import BasketLibrary from "../../../libraries/BasketLibrary.mjs";
import { sendData } from "../../utils.js";
import { basketBadge } from "../../utils.js";

export function checkboxes(newList) {
    const basketList = newList ? newList : document.querySelector('.__basket_append');
    if(!basketList) return;
    let allCheckboxes = basketList.querySelectorAll('.__select_card__basket');
    let disabledCheckboxes = Array.from(allCheckboxes).filter(item=>item.classList.contains('disabled'));
    let basketCheckboxes = Array.from(allCheckboxes).filter(item=>!item.classList.contains('disabled'));
    if(basketCheckboxes.length + disabledCheckboxes.length === 0) return;
    const goToOrderBtn = document.querySelector('.__go_to_order__btn');
    basketCheckboxes.forEach(checkbox=>{
        checkbox.addEventListener('click',()=>{
            checkAllChecked();
            calculateTotal();
        });
    });
    const checkboxAll = document.querySelector('#select-all-basket');
    if(allCheckboxes.length === disabledCheckboxes.length) {
        goToOrderBtn.disabled = true;
        checkboxAll.disabled = true;
        checkboxAll.checked = false;
    };
    checkboxAll.addEventListener('click',()=>{
        if(basketCheckboxes.length === 0) return;
        basketCheckboxes.map(item=>item.checked = checkboxAll.checked);
        goToOrderBtn.disabled = !checkboxAll.checked;
        calculateTotal();
    });
    const deleteBtns = basketList.querySelectorAll('.__delete_basket__btn');
    deleteBtns.forEach(btn=>{
        const card = btn.closest('.product-card-basket');
        const basketId = btn.dataset.id;
        const basketBtnClass = new BasketLibrary(btn,{
            product_id: basketId,
            badge: basketBadge,
            quantity: 1,
        });
        btn.addEventListener('click',()=>{
            card.parentElement.removeChild(card);
            calculateTotal();
            const deleteBtnsNew = basketList.querySelectorAll('.__delete_basket__btn');
            if(deleteBtnsNew.length == 0) window.location.reload();
        });
    });

    function checkAllChecked() {
        let count = 0;
        const promises = basketCheckboxes.map(item=>{
            if(item.checked) count++;
        });
        Promise.all(promises).then(()=>{
            goToOrderBtn.disabled = count === 0;
            checkboxAll.checked = count === basketCheckboxes.length;
        });
    };
    function calculateTotal() {
        const data = [];
        const promises = basketCheckboxes.map(item=>{
            if(!item.checked) return;
            data.push({
                'product_id': parseInt(item.dataset.id),
                'quantity': 1
            });
        });
        Promise.all(promises).then(()=>{
            sendData(data, '/api/basket/calculate-price/')
            .then(res=>{
                document.querySelector('.__product_count').innerText = res.product_count;
                document.querySelector('.__total_base_price').innerText = res.total_base_price;
                document.querySelector('.__total_discount').innerText = res.total_discount;
                document.querySelector('.__total_price').innerText = res.total_price;
                document.querySelector('.__total_cashback').innerText = res.bonuses_count;
            });
        });
    };

    calculateTotal();
}