import { setNewQuery } from "./utils.js";

export function calendar() {
    new AirDatepicker('#calendar', {
        dateFormat: 'dd.MM.yyyy',
        minDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
    });
    new AirDatepicker('#birth-calendar', {
        dateFormat: 'dd.MM.yyyy',
        maxDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
    });
    new AirDatepicker('#date-from', {
        dateFormat: 'dd.MM.yyyy',
        dateDelimiter: '.',
        autoClose: true,
    });
    new AirDatepicker('#date-to', {
        dateFormat: 'dd.MM.yyyy',
        dateDelimiter: '.',
        autoClose: true,
    });
    new AirDatepicker('#date-delivery-calendar', {
        dateFormat: 'dd.MM.yyyy',
        minDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
    });
    const dateFilter = document.querySelector('#filter-date-calendar');
    new AirDatepicker('#filter-date-calendar', {
        dateFormat: 'dd.MM.yyyy',
        maxDate: new Date(),
        dateDelimiter: '.',
        autoClose: true,
        onSelect: () =>{
            setNewQuery('date', dateFilter.value)
        }
    });
}