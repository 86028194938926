export function searchCities() {
    const searchInput = document.querySelector('#input-header-search-city');
    const searchBody = document.querySelector('.city-choose-menu__body');
    if(!searchInput || !searchBody) return;
    const items = searchBody.querySelectorAll('.city-choose-menu__body__item');
    searchInput.addEventListener('input',()=>{
        items.forEach(item=>{
            const links = item.querySelectorAll('.link');
            links.forEach(link=>{
                if(link.innerText.toLowerCase().includes(searchInput.value.toLowerCase())) {
                    link.dataset.state = null; 
                } else {
                    link.dataset.state = 'd-none';
                }
            });
            const linksDNone = item.querySelectorAll('.link[data-state="d-none"]');
            if(linksDNone.length === links.length) {
                item.dataset.state = 'd-none';
            } else {
                item.dataset.state = null;
            }
        });
    });
}