import { getBasketFromCookie } from "../../../libraries/BasketLibrary.mjs";
import { getData, userLoggedIn } from "../../utils.js";
import { checkboxes } from "./checkboxes.js";
import { createBasketCard } from "./createBasketCard.js";

export function appendCards() {
    const basketList = document.querySelector('.__basket_append');
    if(!basketList || userLoggedIn()) return;
    const basket = getBasketFromCookie();
    const fragment = document.createDocumentFragment();
    const promises = basket.map((key)=>{
        return getData(`/api/products/${key.product_id}/`)
            .then(async (res) => {
                const basketCard = await createBasketCard(res);
                fragment.appendChild(basketCard);
            })
            .catch(err => console.error('Error creating card:', err));
    });
    Promise.all(promises).then(() => {
        basketList.appendChild(fragment);
        checkboxes(basketList);
    });
}