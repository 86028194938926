import BasketLibrary from "../libraries/BasketLibrary.mjs";
import WishlistLibrary from "../libraries/WishlistLibrary.mjs";
import { basketBadge, wishlistBadge } from "./utils.js";

export async function createCard(info, newClasses) {
    const changeSize = document.querySelector('.__change_size_display__btn:checked');

    const card = document.createElement('div');
    if (!!newClasses) {
        changeSize && changeSize.dataset.value == 'inline' ? card.classList.add('product-card','product-card_horizontal', newClasses) : card.classList.add('product-card', newClasses);
    } else {
        changeSize && changeSize.dataset.value == 'inline' ? card.classList.add('product-card','product-card_horizontal') : card.classList.add('product-card');
    }
    
    

    let bonusesCount = '';
    if(!!info.bonuses_count && info.bonuses_count !== 0) {
        bonusesCount = `
            <div class="product-card__info-block__price-block__tag">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.5998 5.40006C12.7771 5.16368 12.9061 4.8947 12.9794 4.60847C13.0527 4.32224 13.069 4.02436 13.0272 3.73186C12.9854 3.43936 12.8864 3.15795 12.7359 2.9037C12.5853 2.64945 12.3862 2.42734 12.1498 2.25006C11.9134 2.07277 11.6444 1.94378 11.3582 1.87045C11.072 1.79712 10.7741 1.78089 10.4816 1.82267C10.1891 1.86446 9.90769 1.96345 9.65344 2.11399C9.3992 2.26453 9.17709 2.46368 8.9998 2.70006C8.64176 2.22267 8.10874 1.90706 7.518 1.82267C6.92726 1.73828 6.32719 1.89202 5.8498 2.25006C5.37242 2.6081 5.05681 3.14112 4.97242 3.73186C4.88803 4.3226 5.04176 4.92267 5.3998 5.40006H2.9248C2.3038 5.40006 1.7998 5.90406 1.7998 6.52506V6.97506C1.7998 7.59606 2.3038 8.10006 2.9248 8.10006H8.3248V5.40006H9.67481V8.10006H15.0748C15.6958 8.10006 16.1998 7.59606 16.1998 6.97506V6.52506C16.1998 5.90406 15.6958 5.40006 15.0748 5.40006H12.5998ZM11.6998 4.05006C11.6998 4.28875 11.605 4.51767 11.4362 4.68645C11.2674 4.85524 11.0385 4.95006 10.7998 4.95006H9.89981V4.05006C9.89981 3.81136 9.99463 3.58244 10.1634 3.41366C10.3322 3.24488 10.5611 3.15006 10.7998 3.15006C11.0385 3.15006 11.2674 3.24488 11.4362 3.41366C11.605 3.58244 11.6998 3.81136 11.6998 4.05006ZM6.2998 4.05006C6.2998 4.28875 6.39463 4.51767 6.56341 4.68645C6.73219 4.85524 6.96111 4.95006 7.1998 4.95006H8.0998V4.05006C8.0998 3.81136 8.00498 3.58244 7.8362 3.41366C7.66742 3.24488 7.4385 3.15006 7.1998 3.15006C6.96111 3.15006 6.73219 3.24488 6.56341 3.41366C6.39463 3.58244 6.2998 3.81136 6.2998 4.05006Z" fill="#0BD20B"/>
                    <path d="M8.3252 9.44995H2.7002V13.725C2.7002 14.3814 2.96095 15.0109 3.42511 15.475C3.88926 15.9392 4.51878 16.2 5.1752 16.2H8.3252V9.44995ZM9.6752 16.2V9.44995H15.3002V13.725C15.3002 14.3814 15.0394 15.0109 14.5753 15.475C14.1111 15.9392 13.4816 16.2 12.8252 16.2H9.6752Z" fill="#0BD20B"/>
                </svg>
                ${info.bonuses_count} ₽
            </div>
        `;
    };
    let discount = '';
    if(info.discount !== 0) {
        let percentsDiscount = '';
        if(info.is_percent_discount) percentsDiscount = `data-percent="-${info.discount}%"`;
        discount = `
            <div class="product-card__info-block__price-block__price_prev" ${percentsDiscount}>
                <span>${info.base_price}</span>
            </div>
        `;
    }

    let photos = '';
    let tabs = '';
    if(info.photos.length !== 0) {
        info.photos.forEach((photo, index)=>{
            const active = index == 0 ? 'active': '';
            const photoElem = `
                <img src="${photo.photo}" alt="Product image" data-image="${index + 1}" class="${active}">
            `;
            const tabElem = `
                <div class="product-card__image-block__tabs__tab ${active}" data-tab="${index + 1}"></div>
            `;

            photos += photoElem;
            tabs += tabElem;
        });
    } else {
        photos = `
            <img src="/static/assets/images/stub.webp" alt="Product image" class="active">
        `;
        tabs = `
            <div class="product-card__image-block__tabs__tab active"></div>
        `;
    }

    card.innerHTML = `
        <div class="product-card__container">
            <div class="product-card__bg"></div>
            <a href="/catalog/p/${info.slug}" class="product-card__image-block">
                ${photos}
                <div class="product-card__image-block__tabs">
                    ${tabs}
                </div>
            </a>
            <div class="product-card__info-block">
                <a href="/catalog/p/${info.slug}" class="product-card__info-block__price-block">
                    <div class="product-card__info-block__price-block__main">
                        <div class="product-card__info-block__price-block__price">
                            ${info.price} ₽
                        </div>
                        ${bonusesCount}
                    </div>
                    ${discount}
                </a>
                <a href="/catalog/p/${info.slug}" class="product-card__info-block__title-block">
                    ${info.name}
                </a>
                <div class="product-card__info-block__chars-block">
                    <span class="product-card__info-block__chars-block__char">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.72104 8.41243C1.8138 8.5638 1.94352 8.69351 2.20294 8.95294L7.95441 14.7044C8.84545 15.5955 9.29097 16.041 9.80471 16.2079C10.2566 16.3547 10.7434 16.3547 11.1953 16.2079C11.709 16.041 12.1545 15.5955 13.0456 14.7044L14.7044 13.0456C15.5955 12.1545 16.041 11.709 16.2079 11.1953C16.3547 10.7434 16.3547 10.2566 16.2079 9.80471C16.041 9.29097 15.5955 8.84545 14.7044 7.95442L14.7044 7.95441L8.95294 2.20294L8.95294 2.20294C8.69351 1.94352 8.5638 1.8138 8.41243 1.72104C8.27822 1.6388 8.1319 1.57819 7.97885 1.54145C7.80622 1.5 7.62277 1.5 7.25589 1.5L3.9 1.5C3.05992 1.5 2.63988 1.5 2.31901 1.66349C2.03677 1.8073 1.8073 2.03677 1.66349 2.31901C1.5 2.63988 1.5 3.05992 1.5 3.9L1.5 7.25589C1.5 7.62277 1.5 7.80622 1.54145 7.97885C1.57819 8.1319 1.6388 8.27822 1.72104 8.41243ZM6 6.375C6.20711 6.375 6.375 6.20711 6.375 6C6.375 5.79289 6.20711 5.625 6 5.625C5.79289 5.625 5.625 5.79289 5.625 6C5.625 6.20711 5.79289 6.375 6 6.375Z" fill="#383838" fill-opacity="0.3"/>
                        </svg>
                        ${info.size}
                    </span>
                    <span class="product-card__info-block__chars-block__char">
                        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M8.25332 3.44136C8.48518 2.83853 8.60111 2.53711 8.76847 2.44968C8.91352 2.37389 9.08648 2.37389 9.23153 2.44968C9.39889 2.53711 9.51482 2.83853 9.74668 3.44136L10.509 5.42331C10.7205 5.97324 10.8262 6.2482 10.9907 6.47948C11.1364 6.68447 11.3155 6.86356 11.5205 7.00931C11.7518 7.17377 12.0268 7.27952 12.5767 7.49103L14.5586 8.25332C15.1615 8.48518 15.4629 8.60111 15.5503 8.76847C15.6261 8.91352 15.6261 9.08648 15.5503 9.23153C15.4629 9.39889 15.1615 9.51482 14.5586 9.74668L12.5767 10.509C12.0268 10.7205 11.7518 10.8262 11.5205 10.9907C11.3155 11.1364 11.1364 11.3155 10.9907 11.5205C10.8262 11.7518 10.7205 12.0268 10.509 12.5767L9.74668 14.5586C9.51482 15.1615 9.39889 15.4629 9.23153 15.5503C9.08648 15.6261 8.91352 15.6261 8.76847 15.5503C8.60111 15.4629 8.48518 15.1615 8.25332 14.5586L7.49103 12.5767C7.27952 12.0268 7.17377 11.7518 7.00931 11.5205C6.86356 11.3155 6.68447 11.1364 6.47948 10.9907C6.2482 10.8262 5.97324 10.7205 5.42331 10.509L3.44136 9.74668C2.83853 9.51482 2.53711 9.39889 2.44968 9.23153C2.37389 9.08648 2.37389 8.91352 2.44968 8.76847C2.53711 8.60111 2.83853 8.48518 3.44136 8.25332L5.42331 7.49103C5.97324 7.27952 6.2482 7.17377 6.47948 7.00931C6.68446 6.86356 6.86356 6.68446 7.00931 6.47948C7.17377 6.2482 7.27952 5.97324 7.49103 5.42331L8.25332 3.44136Z" fill="#383838" fill-opacity="0.3"/>
                        </svg>
                        ${info.quality}
                    </span>
                </div>
                <div class="product-card__accordion">
                    <div class="product-card__button-block">
                        <button class="button button_size_basic button_accent __add_in_basket" data-id="${info.id}">
                            Купить
                        </button>
                        <button class="icon-button icon-button_size_basic icon-button_outline __add_in_wishlist" data-id="${info.id}">
                            <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M14.4795 25.3726L14.472 25.3681L14.4435 25.3531C13.8516 25.031 13.2703 24.6899 12.7005 24.3301C11.342 23.4753 10.0476 22.5228 8.8275 21.4801C6.0675 19.0996 3 15.5281 3 11.2501C3.00013 9.85428 3.43297 8.49284 4.23893 7.35323C5.04488 6.21361 6.1843 5.35187 7.5003 4.88667C8.8163 4.42146 10.2442 4.37566 11.5873 4.75559C12.9304 5.13551 14.1227 5.92246 15 7.00809C15.8773 5.92246 17.0696 5.13551 18.4127 4.75559C19.7558 4.37566 21.1837 4.42146 22.4997 4.88667C23.8157 5.35187 24.9551 6.21361 25.7611 7.35323C26.567 8.49284 26.9999 9.85428 27 11.2501C27 15.5281 23.934 19.0996 21.1725 21.4801C19.439 22.9612 17.557 24.2591 15.5565 25.3531L15.528 25.3681L15.5205 25.3726H15.5175C15.3581 25.457 15.1805 25.5013 15.0002 25.5016C14.8198 25.5018 14.6421 25.4581 14.4825 25.3741L14.4795 25.3726Z" fill="#383838"/>
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    `;

    const wishlistBtn = card.querySelector('.__add_in_wishlist');
    const wishlistId = wishlistBtn.dataset.id;
    const wishlistBtnClass = new WishlistLibrary(wishlistBtn,{
        product_id: wishlistId,
        badge: wishlistBadge
    });
    
    const basketBtn = card.querySelector('.__add_in_basket');
    const basketId = basketBtn.dataset.id;
    const basketBtnClass = new BasketLibrary(basketBtn,{
        product_id: basketId,
        badge: basketBadge,
        quantity: 1,
        activeInnerHTML: 'В корзине',
        oldInnerHTML: 'Купить'
    });

    return card;
}