export function productCardAnimations(section) {
    const productCards = section ? section.querySelectorAll('.product-card') : document.querySelectorAll('.product-card');

    if(productCards.length === 0) return; 
    productCards.forEach(card=>{
        cardAnimateFunc(card);
    });
}

function cardAnimateFunc(card) {
    const imagesBlock = card.querySelector('.product-card__image-block');
    const tabsBlock = imagesBlock.querySelector('.product-card__image-block__tabs');

    const images = imagesBlock.querySelectorAll('img');
    const tabs = tabsBlock.querySelectorAll('.product-card__image-block__tabs__tab');
    tabs.forEach(tab=>{
        const dataTab = tab.dataset.tab;
        const tabImage = imagesBlock.querySelector(`[data-image="${dataTab}"]`);
        tab.addEventListener('mouseover',()=>{
            tabs.forEach(notHovered=>notHovered.classList.remove('active'));
            images.forEach(notHovered=>{notHovered.classList.remove('active')});
            tab.classList.add('active');
            tabImage && tabImage.classList.add('active');
        });
    });

    if(window.innerWidth < 769) return;


    const container = card.querySelector('.product-card__container');
    const accordion = card.querySelector('.product-card__accordion');

    const newContainerHeight = container.scrollHeight + accordion.scrollHeight + 'px';

    card.addEventListener('mouseover',()=>{
        if(card.className !== 'product-card') return;
        accordion.style.maxHeight = accordion.scrollHeight + "px";
        container.style.height = newContainerHeight;
        card.classList.add('active');
    });
    card.addEventListener('mouseleave',()=>{
        if(card.className !== 'product-card active') return;
        accordion.style.maxHeight = null;
        container.style.height = null;
        card.classList.remove('active');
        tabs.forEach(tab => tab.classList.toggle('active', tab.dataset.tab === '1'));
        images.forEach(image => image.classList.toggle('active', image.dataset.image === '1'));
    });
}