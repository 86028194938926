export function addReviewCards(data, section) {
    const fragment = document.createDocumentFragment();
    data.forEach(elem=>{
        const card = document.createElement('div');
        card.classList.add('review-card');

        let answer = '';
        if(elem.reply !== '') {
            answer = `
                <div class="review-card__answer-block accordion-item">
                    <div class="review-card__answer-block__header-block">
                        <button class="link link_size_basic link_subdued">
                            Ответ представителя
                            <svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.48031 9.13101C5.62796 8.98354 5.82812 8.90071 6.03681 8.90071C6.2455 8.90071 6.44565 8.98354 6.59331 9.13101L10.4993 13.037L14.4053 9.13101C14.4774 9.05364 14.5643 8.99159 14.6609 8.94854C14.7575 8.9055 14.8618 8.88236 14.9676 8.88049C15.0733 8.87863 15.1783 8.89808 15.2764 8.93769C15.3744 8.97729 15.4635 9.03625 15.5383 9.11102C15.6131 9.1858 15.672 9.27488 15.7116 9.37294C15.7512 9.47099 15.7707 9.57602 15.7688 9.68176C15.767 9.7875 15.7438 9.89178 15.7008 9.98838C15.6577 10.085 15.5957 10.1719 15.5183 10.244L11.0558 14.7065C10.9081 14.854 10.708 14.9368 10.4993 14.9368C10.2906 14.9368 10.0905 14.854 9.94281 14.7065L5.48031 10.244C5.33283 10.0964 5.25 9.8962 5.25 9.68751C5.25 9.47883 5.33283 9.27867 5.48031 9.13101Z" fill="#383838" fill-opacity="0.6"/>
                            </svg>
                        </button>
                    </div>
                    <div class="review-card__answer-block__body accordion-item__body">
                        <div class="review-card__answer-block__content">
                            ${elem.reply}
                        </div>
                    </div>
                </div>
            `;
        }
            
        card.innerHTML = `
            <div class="review-card__title-block">
                <div class="review-card__title-block__name">
                    ${elem.name}
                </div>
                <div class="review-card__rating-block">
                    <div class="review-card__rating-block__rating"
                        data-rate="${elem.rating}">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                    </div>
                    <div class="review-card__rating-block__date">
                        ${elem.created_at}
                    </div>
                </div>
            </div>
            <div class="review-card__text-block">
                ${elem.message}
            </div>
            <div class="review-card__rating-block_mobile">
                <div class="review-card__rating-block__rating"
                        data-rate="${elem.rating}">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M13.0413 3.96091C12.6561 3.03451 11.3433 3.03451 10.9581 3.96091L8.76211 9.24211L3.0585 9.69931C2.0589 9.77971 1.6533 11.0277 2.4153 11.6805L6.75931 15.4029L5.4321 20.9673C5.1993 21.9429 6.26131 22.7145 7.11811 22.1913L11.9997 19.2093L16.8825 22.1913C17.7381 22.7145 18.8001 21.9429 18.5673 20.9673L17.2401 15.4029L21.5841 11.6805C22.3461 11.0277 21.9405 9.77971 20.9409 9.70051L15.2385 9.24211L13.0413 3.96091Z" fill="#383838" fill-opacity="0.15"/>
                        </svg>
                    </div>
                    <div class="review-card__rating-block__date">
                        ${elem.created_at}
                    </div>
            </div>
            ${answer}
        `;

        fragment.appendChild(card);
    });
    section.appendChild(fragment)
}