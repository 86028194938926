import { openModal } from "./base/modals.js";
import { setCookie, userLoggedIn } from "./utils.js";
export function getFullUserInfo() {
  const needUpdateCookieStatus = document.cookie.replace(
    /(?:(?:^|.*;\s*)needGetFullInfo\s*\=\s*([^;]*).*$)|^.*$/,
    "$1"
  ) == 'true';

  console.log(needUpdateCookieStatus);

  if (needUpdateCookieStatus) {
    openModal("updateUserInfo");
    return;
  }

  const csrfToken = document.querySelector("[name=csrfmiddlewaretoken]").value;
  let token = userLoggedIn();

  async function makeRequest(token) {
    const headers = {
      "Content-Type": "application/json",
      "X-CSRFToken": csrfToken,
    };

    if (token) {
      headers["Authorization"] = "Bearer " + token;
    }

    const response = await fetch("/api/profile/get-info/", {
      method: "GET",
      headers: headers,
    });
    if (response.ok) {
      return response.json();
    } else if (response.status === 401 && token) {
      try {
        token = await refreshAccessToken();
        setCookie("access_token", token);
        return makeRequest(token);
      } catch (error) {
        throw new Error("Failed to refresh token");
      }
    } else {
      const errorData = await response.json().catch(() => ({}));
      const error = new Error(`Request failed with status ${response.status}`);
      error.status = response.status;
      error.data = errorData;
      throw error;
    }
  }

  makeRequest(token).then((res) => {
    const hasNeedFields = res.first_name != null && res.last_name != null;
    console.log(hasNeedFields);
    if (!hasNeedFields) {
      setCookie("needGetFullInfo", "true");
      openModal("updateUserInfo");
    }
  });
}
