import { getWishlistFromCookie } from '../../../libraries/WishlistLibrary.mjs';
import { getCurrentQuery, sendData, setNewQuery, userLoggedIn } from '../../utils.js';
export function filtersWishlist() {
    const list = document.querySelector('.__wishlist_filters__list');
    if(!list) return;
    if(!userLoggedIn()) {
        const cookie = getWishlistFromCookie();
        if(cookie.length === 0) return;
        sendData(cookie, '/api/wishlist/get-filter-category/')
        .then(res=>{
            const fragment = document.createDocumentFragment();
            const promises = res.map(async(info)=>{
                const filterElem = await createFilterElem(info);
                fragment.appendChild(filterElem);
            });
            Promise.all(promises).then(res=>{
                list.appendChild(fragment);
                const newList = document.querySelector('.__wishlist_filters__list');
                const filters = newList.querySelectorAll('.__wishlist_filters__filter');
                filters.forEach(filter=>{
                    filterFunctionality(filters, filter);
                });
            });
        });
    } else {
        const filters = document.querySelectorAll('.__wishlist_filters__filter');
        filters.forEach(filter=>{
            filterFunctionality(filters, filter);
        });
    };
}

function filterFunctionality(filters, filter) {
    const queries = getCurrentQuery();
    if(queries.length > 0) {
        const categoryQuery = getCurrentQuery()[0].category;
        if(categoryQuery && categoryQuery == filter.dataset.value) filter.checked = true;
    }
    filter.addEventListener('click',()=>{
        filters.forEach(unchecked=>{
            if(unchecked !== filter) unchecked.checked = false;
        });
        if(filter.checked) {
            setNewQuery('category', filter.dataset.value);
        } else {
            setNewQuery('category', '');
        }
    });
}

function createFilterElem(data) {
    const label = document.createElement('label');
    label.classList.add('button','button_size_basic','button_outline');
    label.for = `title-filter-categories-${data.id}`;
    label.innerHTML = `
        ${data.name}
        <input type="checkbox" 
            id="title-filter-categories-${data.slug}"
            name="title-categories-filter"
            class="__wishlist_filters__filter"
            data-value="${data.id}"
        >
    `;

    return label;
}