import { closeAllModals } from "../base/modals.js";
import { hideInset, showInset } from "../inset.js";
import { sendData } from "../utils.js";
import { closeSearcher } from "./headerSearch.js";

export function headerMenus() {
    const header = document.querySelector('header');

    const headerMenuBtns = document.querySelectorAll('[data-header-btn]');
    if(headerMenuBtns.length > 0) {
        headerMenuBtns.forEach(btn=>{

            const btnAttr = btn.dataset.headerBtn;
            btn.addEventListener('click',()=>{
                const dataState = btn.dataset.btnState;
                if(!dataState || dataState == 'close') {
                    openHeaderMenu(btnAttr);

                    subheadingFixed();

                    header.removeEventListener('mouseleave', closeAllMenus);
                } else if(dataState == 'open') {
                    closeAllMenus();
                };
            });

        });
    };

    const headerMenuBtnsHover = document.querySelectorAll('[data-header-btn-hover]');
    if(headerMenuBtnsHover.length > 0) {
        headerMenuBtnsHover.forEach(btn=>{

            const btnAttr = btn.dataset.headerBtnHover;
            btn.addEventListener('mouseover',()=>{
                const openedMenus = document.querySelectorAll('[data-header-menu-state="open"]');
                let returnState = false;
                if(openedMenus.length > 0) {
                    openedMenus.forEach(menu=>{
                        if(menu.dataset.headerMenu == 'code-login' || 
                           menu.dataset.headerMenu == 'code-money'
                        ) returnState = true;
                    });
                };
                
                if(returnState) return;

                openHeaderMenu(btnAttr);

                subheadingFixed();

                header.removeEventListener('mouseleave', closeAllMenus);
                header.addEventListener('mouseleave', closeAllMenus);
            });
        });
    };

    const headerMenuBtnsClose = document.querySelectorAll('[data-header-btn-close]');
    if(headerMenuBtnsClose.length > 0) {
        headerMenuBtnsClose.forEach(btn=>{
            btn.addEventListener('click',()=>{
                closeAllMenus()
            });
        });
    };

    
    const ctaMobile = document.querySelectorAll('.cta-button-mobile');
    if(ctaMobile.length > 0) {
        ctaMobile.forEach(btn=>{
            let startedMove = 0;
            let menu = '';
            if(btn.closest('[data-header-menu]')) menu = btn.closest('[data-header-menu]');
            if(btn.closest('.modal__content')) menu = btn.closest('.modal__content');
            let startedTop = 0;
            btn.addEventListener('touchstart',(event)=>{
                const startOfMove = event.touches[0].clientY;

                startedMove = startOfMove;
                startedTop = menu.style.top;
                if(!btn.classList.contains('cta-button-mobile_reverse')) return;
                menu.style.position = 'absolute';
            });
            btn.addEventListener('touchmove',(event)=>{
                const move = event.touches[0].clientY;
                if(!btn.classList.contains('cta-button-mobile_reverse')) {
                    if(startedMove > move) {
                        menu.style.top = 'auto';
                        menu.style.bottom = window.innerHeight - move + 'px';
                    };
                } else {
                    if(startedMove < move) {
                        menu.style.top = move + 'px';
                        // menu.style.bottom = window.innerHeight - move + 'px';
                    };
                }
            });
            btn.addEventListener('touchend',(event)=>{
                const endOfMove = event.changedTouches[0].clientY;
                if(!btn.classList.contains('cta-button-mobile_reverse')) {
                    if(startedMove > endOfMove + 100) {
                        closeAllMenus();
                        closeAllModals();
    
                        menu.style.top = null;
                        menu.style.bottom = null;
                    } else {
                        menu.style.top = startedTop;
                        menu.style.bottom = null;
                    };
                } else {
                    if(startedMove < endOfMove + 100) {
                        closeAllMenus();
                        closeAllModals();
    
                        menu.style.top = null;
                        menu.style.bottom = null;
                    } else {
                        menu.style.top = startedTop;
                        menu.style.bottom = null;
                    };
                }
            });
        });
    };
}

export function closeAllMenus(InsetShow) {
    const header = document.querySelector('header');
    const headerMenuBtns = document.querySelectorAll('[data-header-btn]');
    const headerMenus = document.querySelectorAll('[data-header-menu]');

    headerMenus.forEach(menu=>{
        if(menu.dataset.headerMenu !== 'subheading') menu.dataset.headerMenuState = 'close';
        menu.style.top = null;
    });
    headerMenuBtns.forEach(btn=>{
        btn.dataset.btnState = 'close';
    });

    header.removeEventListener('mouseleave', closeAllMenus);
    closeSearcher();
    setTimeout(() => {
        document.querySelector('.header-section').classList.remove('header-section_bordered');
    }, 300);
    subheadingStatic();
    if(InsetShow == true) return;
    setTimeout(() => {
        hideInset();
    }, 300);
}
export function openHeaderMenu(dataAttr) {
    const headerSection = document.querySelector('.header-section');
    const headerMenus = document.querySelectorAll('[data-header-menu]');
    const headerMenuBtns = document.querySelectorAll('[data-header-btn]');

    showInset();

    if(dataAttr == 'code-login') {
        codeMenuFunctionary('code-login', '/api/authentication/get-code/');
    }
    if(dataAttr == 'code-money') {
        codeMenuFunctionary('code-money', '/api/promocode/get-code/');
    }

    if(window.innerWidth < 769 && dataAttr !== 'burger' && dataAttr !== 'login' && dataAttr !== 'code') {
        headerSection.classList.add('header-section_bordered');
    } else if (window.innerWidth < 769 && dataAttr == 'burger') {
        headerSection.classList.remove('header-section_bordered');
    }

    headerMenus.forEach(menu=>{
        if(menu.dataset.headerMenu == dataAttr) {
            menu.dataset.headerMenuState = 'open';
            if(window.innerWidth > 768) {
                headerSection.classList.add('header-section_bordered');
                menu.style.top = document.querySelector('[data-header-menu="subheading"]').scrollHeight + 'px';
            } else {
                menu.style.top = headerSection.scrollHeight + 'px';
            }
        } else {
            menu.dataset.headerMenuState = 'close';
            menu.style.top = null;
        };
    });
    headerMenuBtns.forEach(btn=>{
        if(btn.dataset.headerBtn == dataAttr) {
            btn.dataset.btnState = 'open';
        } else {
            btn.dataset.btnState = 'close';
        }
    });
}

export function subheadingFixed() {
    const subHeading = document.querySelector('[data-header-menu="subheading"]');
    const main = document.querySelector('main');

    main.style.paddingTop = subHeading.scrollHeight + 'px';
    if(!window.pageYOffset == 0) {
        subHeading.dataset.animate = 'open';
    };
    subHeading.dataset.headerMenuState = 'open';
}
function subheadingStatic() {
    const subHeading = document.querySelector('[data-header-menu="subheading"]');
    const main = document.querySelector('main');

    if(window.pageYOffset == 0) {
        setTimeout(() => {
            main.style.paddingTop = null;
            subHeading.dataset.headerMenuState = null;
        }, 300);
    } else {
        subHeading.dataset.animate = 'close';
        setTimeout(() => {
            subHeading.dataset.headerMenuState = null;
            main.style.paddingTop = null;
            subHeading.dataset.animate = 'open';
        }, 300);
    };
}



export function codeMenuFunctionary(attr, api) {
    let codeMenu = document.querySelector(`[data-header-menu="${attr}"]`);
    if(!codeMenu) codeMenu = document.querySelector(`[data-type="${attr}"]`);
    const codeInput = codeMenu.querySelector('[name="code"]');
    codeInput.addEventListener('input',()=>{
        if(codeInput.value.length === 4) codeInput.form.elements.button.click();
    });

    countdown(60, codeMenu, api);
}
export function countdown(duration, menu, api) {
    const block = menu.querySelector('.__code_menu_timer');
    const restart = menu.querySelector('.__code_menu_reask');
    var timer = duration, minutes, seconds;
    restart.classList.add('link_disabled');
    setTimer();
    restart.onclick = () =>{
        const body = {};
        const codeFormPhone = menu.querySelector('[name="phone"]');
        body.phone = codeFormPhone.value;
        setTimer();
        sendData(body, api);
        restart.classList.add('link_disabled');
    };
    function setTimer() {
        const timerInterval = setInterval(function () {
            minutes = parseInt(timer / 60, 10);
            seconds = parseInt(timer % 60, 10);
    
            minutes = minutes < 10 ? "0" + minutes : minutes;
            seconds = seconds < 10 ? "0" + seconds : seconds;
    
            block.textContent = minutes + ":" + seconds;
    
            if(--timer < 0) {
                timer = duration;
                clearInterval(timerInterval);
                restart.classList.remove('link_disabled');
            }
        }, 1000);
    }
}