import { getBasketFromCookie } from "../../../libraries/BasketLibrary.mjs";
import { deleteData, setCookie, userLoggedIn } from "../../utils.js";

export function clearBasket() {
    const deleteBtn = document.querySelector('.__delete_basket_selected');
    if(!deleteBtn) return;
    deleteBtn.addEventListener('click',()=>{
        const basketList = document.querySelector('.__basket_append');
        const checkboxes = basketList.querySelectorAll('.__select_card__basket');
        if(userLoggedIn()) {
            const promises = Array.from(checkboxes).map(item=>{
                if(!item.checked) return;
                deleteData({'product_id': parseInt(item.dataset.id)}, '/api/basket/remove/');
            });
            Promise.all(promises).then(()=>{
                window.location.reload();
            });
        } else {
            const basket = getBasketFromCookie();
            const promises = Array.from(checkboxes).map(checkbox=>{
                if(checkbox.checked || checkbox.classList.contains('disabled')) {
                    const id = parseInt(checkbox.dataset.id);
                    let index;
                    index = basket.findIndex(item => item.product_id === id);

                    if(index !== -1) basket.splice(index, 1);
                } else {
                    return;
                }
            });
            Promise.all(promises).then(()=>{
                setCookie('basket', JSON.stringify(basket));
                window.location.reload();
            });
        };
    });
}