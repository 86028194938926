import { calculateOrderPrice } from "./deliveryOrder.js";

export function createAddressLabel(list, address, city) {
    const label = document.createElement('label');
    label.classList.add('custom-radio-label');
    label.for = `delivery-address-${address}`;

    const radios = list.querySelectorAll('input[type="radio"]');
    radios.forEach(radio=>{
        radio.checked = false;
    });

    label.innerHTML = `
        <input type="radio" 
            name="delivery_address__selector"
            id="delivery-address-${address}"
            data-city="${city}"
            checked
        >${address}
    `;
    calculateOrderPrice(city, '137');

    const input = label.querySelector('input');
    input.addEventListener('click',()=>{
        if(input.checked) {
            calculateOrderPrice(city, '137');
        }
    });

    list.appendChild(label);
};