// async function getConfig() {
//     try {
//         const response = await fetch('/package.json');
//         if (!response.ok) {
//             throw new Error('Error fetching package.json: ' + response.statusText);
//         }
//         const packageJson = await response.json();
//         return packageJson.basketConfig;
//     } catch (error) {
//         console.error('Error loading package.json:', error);
//     }
// }

let productKey = 'product_id';
let productQuantity = 'quantity';
// const awaitConfigProduct = await getConfig().then(config => {productKey = config.productKey});
// const awaitConfigQuantity = await getConfig().then(config => {productQuantity = config.quantityKey});

class BasketClass{
    constructor(options) {
        this[productKey] = options[productKey],
        this[productQuantity] = options[productQuantity]
    }
}

class BasketCounter extends BasketClass{
    constructor(options) {
        super(options),
        this.counter = options.counter
    }
    counterFunctionality() {
        
    }
}

class AddToBasket extends BasketCounter {
    constructor(options) {
        super(options)

        this[productQuantity] = options[productQuantity]
    }
    addToBasketFunctionality() {
        const id = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        const quantity = isNaN(this[productQuantity]) ? this[productQuantity] : parseInt(this[productQuantity])
        if(userLoggedIn()) {
            const productObj = {};
            productObj[productKey] = id;
            productObj[productQuantity] = quantity;
            sendData(productObj, '/api/basket/add/');
        } else {
            const basket = getBasketFromCookie();

            let index;
            index = basket.findIndex(item => item[productKey] === id);

            if(index !== -1) basket.splice(index, 1);

            basket.push({
                [productKey]: id,
                [productQuantity]: quantity,
            });

            setCookie('basket', JSON.stringify(basket));
        }
    }
}

class DeleteFromBasket extends AddToBasket {
    constructor(options) {
        super(options)
    }
    deleteFromBasketFunctionality() {
        const id = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        if(userLoggedIn()) {
            const productObj = {};
            productObj[productKey] = id;
            deleteData(productObj, '/api/basket/remove/');
        } else {
            const basket = getBasketFromCookie();

            let index;
            index = basket.findIndex(item => item[productKey] === id);

            if(index !== -1) basket.splice(index, 1);
            setCookie('basket', JSON.stringify(basket));
        }
    }
}


class BasketLibrary extends DeleteFromBasket{
    constructor(Elem, options) {
        super(options),

        this.$Elem = (typeof Elem === 'string') ? document.querySelector(`${Elem}`) : Elem
        this.$Badge = (typeof options.badge === 'string') ? document.querySelectorAll(`${options.badge}`) : options.badge

        this.options = options || {};
        this.activeInnerHTML = options.activeInnerHTML,
        this.oldInnerHTML = options.oldInnerHTML

        this.buttonFunctionality()
        this.checkForBasket()
        if(!this.counter) return;
        this.counterFunctionality()
    }

    checkForBasket() {
        const basket = getBasketFromCookie();
        if(basket.length === 0) return;
        const buttonId = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        let active = false;
        for (const basketItem of basket) {
            const id = isNaN(basketItem[productKey]) ? basketItem[productKey] : parseInt(basketItem[productKey]);
            if(buttonId === id) {
                this.$Elem.dataset.state = 'active';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.activeInnerHTML;
                active = true;
            } else if(buttonId !== id && !active) {
                this.$Elem.dataset.state = 'unactive';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.oldInnerHTML;
            };
        };
    }
    buttonFunctionality() {
        this.$Elem.addEventListener('click',()=>{
            if(!this.$Elem.dataset.state) {
                this.$Elem.dataset.state = 'unactive';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.activeInnerHTML;
            }

            if(this.$Elem.dataset.state === 'active') {
                this.$Elem.dataset.state = 'unactive';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.oldInnerHTML;
                super.deleteFromBasketFunctionality();
            } else if(this.$Elem.dataset.state === 'unactive') {
                this.$Elem.dataset.state = 'active';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.activeInnerHTML;
                super.addToBasketFunctionality();
            };

            if(!this.$Badge || this.$Badge.length === 0) return;
            this.updateBadge()
        });

        if(!this.$Badge || this.$Badge.length === 0) return;
        this.updateBadge()
    }
    updateBadge() {
        if(userLoggedIn()) {
            getData('/api/basket/get-info/')
            .then(res=>{
                for(const badge of this.$Badge) {
                    badge.dataset.value = res.product_count;
                };
            });
        } else {
            const basket = getBasketFromCookie();
            const totalQuantity = basket.reduce((acc, item) => acc + item[productQuantity], 0);
            for(const badge of this.$Badge) {
                badge.dataset.value = totalQuantity;
            };
        };
    }
}


async function getData(url) {
    const response = await fetch(url, {
        method: "GET",
    });
    
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

async function deleteData(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Bearer ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

async function sendData(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Bearer ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

const getCookiesBasket = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)basket\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export function getBasketFromCookie() {
    const basketCookies = getCookiesBasket();
    return basketCookies ? JSON.parse(basketCookies) : [];
}
const userLoggedIn = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
}
  
function setCookie(name, value, options = {}) {
    options = {
        path: '/',
        ...options
    };

    if (options.expires instanceof Date) {
        options.expires = options.expires.toUTCString();
    }

    let updatedCookie = name + "=" + value;

    for (let optionKey in options) {
        updatedCookie += "; " + optionKey;
        let optionValue = options[optionKey];
        if (optionValue !== true) {
            updatedCookie += "=" + optionValue;
        }
    }

    document.cookie = updatedCookie;
};

export default BasketLibrary;