import QueryFilter from "../../../libraries/QueryLibrary.mjs"
import { getCurrentQuery } from "../../utils.js";

export function profileSearch() {
    const inputSearch = document.querySelectorAll('.__profile_search__input');
    if(inputSearch.length === 0) return;
    const filters = new QueryFilter(inputSearch, {
        search: true,
        debounced: true
    });
    const queries = getCurrentQuery();
    let dateQuery = null;
    if(queries.length > 0) {
        dateQuery = getCurrentQuery()[0].date;
    }
    if(!dateQuery) return;
    const filterCalendar = document.querySelector('#filter-date-calendar');
    filterCalendar.value = dateQuery;
}