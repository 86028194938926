export function orderValidateInit() {
    const form = document.querySelector('#order-form');
    if(!form) return;
    const inputs = Object.keys(getBody(form))
        .map(el=> 
            form.elements[`${el}`]
        )
    inputs.forEach(input=>{
        input.addEventListener('input',()=>{
            orderValidate();
        });
    });

    dispatchOrderValidate();
}

export function dispatchOrderValidate() {
    const form = document.querySelector('#order-form');
    const inputs = Object.keys(getBody(form))
        .map(el => form.elements[`${el}`])
        .filter(el => el.type === 'text' || el.type === 'textarea' || el.type === 'password');
    inputs.forEach(input=>{
        input.value = input.value;
        input.dispatchEvent(new Event("input"));
    });
}

function orderValidate() {
    const form = document.querySelector('#order-form');
    const delivery = deliveryValidate(form);
    
    if(delivery) {
        form.dataset.state = 'active';
    } else {
        form.dataset.state = 'disabled';
    }
}

function deliveryValidate(form) {
    const deliveryWay = document.querySelector('[name="delivery_way__selector"]:checked');
    if(deliveryWay.dataset.radio == 'cdek') {
        const address = document.querySelector('.__cdek_map__address');
        if(!!address.dataset.city) {
            form.dataset.cdek_order = address.dataset.id;
            form.dataset.city_name = address.dataset.city;
            form.removeAttribute('data-address');
            return true;
        } else {
            return false;
        }
    }
    if(deliveryWay.dataset.radio == 'courer') {
        const addressList = document.querySelector('.__address_order__list');
        const checkedSelector = addressList.querySelector('[name="delivery_address__selector"]:checked')
        if(!!checkedSelector) {
            form.dataset.address = checkedSelector.parentNode.innerText;
            form.dataset.city_name = checkedSelector.dataset.city;
            form.removeAttribute('data-cdek_order');
            return true;
        } else {
            return false;
        }
    }
}
function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
}