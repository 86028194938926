import { getData } from "./utils.js";

export function refund() {
    const buttons = document.querySelectorAll('[data-type-modal="refund-modal"]');
    const form = document.querySelector('#refund-form');
    if(buttons.length === 0 || !form) return;
    const selectProducts = form.querySelector('.__select_products__block');
    buttons.forEach(btn=>{
        const id = btn.dataset.id;
        const number = btn.dataset.number;

        const refundNumber = document.querySelector('.__refund_number');        
        btn.addEventListener('click',()=>{
            selectProducts.innerHTML = '';
            refundNumber.innerText = number;
            form.dataset.id = id;
            form.dataset.state = 'disabled';
            form.elements.button.disabled = true;
            getData(`/api/order/${form.dataset.id}/`)
            .then(res=>{
                const results = res.items;

                const fragment = document.createDocumentFragment();
                const promises = results.map(async(item)=>{
                    const card = createCheckCard(item);
                    fragment.appendChild(card);
                });
                Promise.all(promises).then(()=>{
                    selectProducts.appendChild(fragment);

                    const products = selectProducts.querySelectorAll('.__select_products__product');
                    products.forEach(product=>{
                        product.addEventListener('click',()=>{
                            let valid = false;
                            Array.from(products).map(item=>{if(item.checked) valid = true});
                            valid ? form.dataset.state = 'active' : form.dataset.state = 'disabled';
                            const textarea = form.querySelector('textarea');
                            const event = new Event("input", {bubbles: true});
                            textarea.dispatchEvent(event);
                        });
                    });
                });
            });
        });
    });
}

function createCheckCard(info) {
    const card = document.createElement('label');
    card.classList.add('modal__content_refund__form-block__product');
    card.for = `check-card-${info.id}`;

    const photo = info.main_photo ? info.main_photo: '/static/assets/images/stub.webp';

    card.innerHTML = `
        <img src="${photo}" alt="product image">
        <input type="checkbox" id="check-card-${info.id}" class="__select_products__product" data-id="${info.id}">
    `;
    return card;
}