import { debounce, sendText } from './utils.js';

export function address() {
    const addressInputMenu = document.querySelectorAll('.__address_input__menu');
    const numberInputs = document.querySelectorAll('.__address_input__number');
    if(addressInputMenu.length === 0) return;
    addressInputMenu.forEach(menu=>{
        const input = menu.querySelector('.__address_input__input');
        const list = menu.querySelector('.__address_input__list');
        input.addEventListener('input',()=>{
            if (addressInputMenu.length > 1) {
                input.form.dataset.state = 'disabled';
                numberInputs.forEach(number => {
                    number.classList.add('disabled');
                    number.value = '';
                });
            }
            debouncedSendApi(input, list, menu.dataset.list);
        });
    });
    const addressInputs = document.querySelectorAll('.__address_input__number');
    addressInputs.forEach(input=>{
        input.addEventListener('input',()=>{
            addressInputs.forEach(same=>{
                if(same.name == input.name && same !== input) same.value = input.value;
            });
            debouncedPlacemarks();
        });
    });

    const privateHouseCheckboxes = document.querySelectorAll('.__private_house__checkbox');
    const privateHouseInputs = document.querySelectorAll('.__private_house__input_clear');
    if(privateHouseCheckboxes.length === 0 || privateHouseInputs.length === 0) return;
    privateHouseCheckboxes.forEach(checkbox=>{
        checkbox.addEventListener('click',()=>{
            privateHouseCheckboxes.forEach(check=>{
                check.checked = checkbox.checked;
            });
            privateHouseInputs.forEach(input=>{
                input.value = '';
                if(checkbox.checked) {
                    input.classList.add('address-disabled');
                } else {
                    input.classList.remove('address-disabled');
                };
            });
        });
    });
}

const debouncedSendApi = debounce(sendApi, 500);
const debouncedPlacemarks = debounce(appendPlacemarks, 500);

function sendApi(input, list, attr) {
    let value = input.value;
    if(attr == 'address') {
        const cityInputMenu = document.querySelector('.__address_input__menu[data-list="city"]');
        const cityInput = cityInputMenu.querySelector('.__address_input__input');
        if(cityInput.value !== '' && input.value !== '') value = cityInput.value + ', ' + input.value;
    };
    sendText('https://suggest-maps.yandex.ru/v1/suggest?apikey=6113898e-6805-4af0-a178-94df8a308a44', value)
    .then(async(res)=>{
        const results = res.results;

        if(!results) return;

        const fragment = document.createDocumentFragment();
        const promises = results.map(async(result)=>{
            if(attr == 'city') {
                if(!result.tags.includes('locality')) return;
                const item = await createListItem(result, attr);
                list.dataset.state = 'active';
                fragment.appendChild(item);
            } else if (attr == 'address') {
                if(!result.tags.includes('street')) return;
                if(result.subtitle && result.subtitle.text.includes(value)) return;
                const item = await createListItem(result, attr);
                list.dataset.state = 'active';
                fragment.appendChild(item);
            }
        });

        Promise.all(promises).then(()=>{
            list.innerHTML = '';
            list.appendChild(fragment);
        });
    });
}

function createListItem(data, attr) {
    const item = document.createElement('div');
    item.classList.add('address-menu__info-list__item');
    item.innerText = `${data.title.text}`;
    
    item.addEventListener('click',()=>{
        const menus = document.querySelectorAll(`.__address_input__menu[data-list="${attr}"]`);
        menus.forEach(menu=>{
            const input = menu.querySelector('.__address_input__input');
            const list = menu.querySelector('.__address_input__list');
            list.dataset.state="disabled";
            list.innerText = '';
            input.value = data.title.text;
        });
        if(attr == 'city') {
            const addresses = document.querySelectorAll(`.__address_input__menu[data-list="address"]`);
            addresses.forEach(menu=>{
                const input = menu.querySelector('.__address_input__input');
                input.value = '';
                input.classList.remove('disabled');
                input.form.dataset.state = 'disabled';
            });
        } else if (attr == 'address') {
            const numberInputs = document.querySelectorAll('.__address_input__number');
            numberInputs.forEach(input=>{
                input.classList.remove('disabled');
                input.form.dataset.state = 'active';
            });
        };

        appendPlacemarks();
    });
    return item;
}

function appendPlacemarks() {
    let address = '';
    const inputCity = document.querySelector('.__address_input__input[name="city"]');
    const inputStreet = document.querySelector('.__address_input__input[name="street"]');
    const inputHouse = document.querySelector('.__address_input__number[name="house"]');
    address = address + inputCity.value;
    address = address + ' ' + inputStreet.value;
    address = address + ' ' + inputHouse.value;

    const mapAddress = document.querySelector('#address-map');
    if(!mapAddress) return;
    let firstCoordinate = '';
    let secondCoordinate = '';
    let myGeocoder = ymaps.geocode(address)
    .then(res=>{
        firstCoordinate = res.geoObjects.get(0).geometry.getCoordinates()['0'];
        secondCoordinate = res.geoObjects.get(0).geometry.getCoordinates()['1'];

        Array.from(mapAddress.children).forEach((el) => {
            mapAddress.removeChild(el);
        });

        ymaps.ready(init);
        var myMap;

        function init() {
            myMap = new ymaps.Map("address-map", {
                center: [Number(firstCoordinate), Number(secondCoordinate)], 
                zoom: 15,
                controls: [],
            });
            setTimeout(() => {
                let placemark = new ymaps.Placemark([Number(firstCoordinate), Number(secondCoordinate)], {}, {
                    iconLayout: "default#image",
                    iconImageHref: '/static/assets/images/map-pin-active.png',
                    iconImageSize: [54, 60],
                    iconImageOffset: [-27, -30],
                });

                myMap.geoObjects.add(placemark)
            }, 50);
        }
    });
}