import { getCurrentQuery, setNewQuery } from "../../utils.js";

export function periodFilters() {
    const filteredItem = document.querySelector('.period__filtered');
    const filters = document.querySelectorAll('.period__filter');
    if(filters.length === 0 || !filteredItem) return;

    filters.forEach(filter=>{
        const currentQueries = getCurrentQuery();
        currentQueries.forEach(query=>{
            if(query.period == filter.dataset.period) {
                filteredItem.querySelector('span').innerHTML = filter.querySelector('span').innerHTML;
            };
        });

        filter.addEventListener('click',()=>{
            setNewQuery('period', filter.dataset.period);
        });
    });
}