import { getRem } from "../../utils.js";

export function stocksStories() {
    const section = document.querySelector('.stocks-stories');
    if(!section) return;
    
    const stories = new Swiper('.__stocks_stories__slider',{
        slidesPerView: 1.2,
        spaceBetween: getRem() * .5,
        centeredSlides: true,
        loop: true,
        breakpoints: {
            769: {
                slidesPerView: 4.84,
                spaceBetween: getRem() * 3.75,
            } 
        },
        autoplay: {
            delay: 5000,
            disableOnInteraction: false,
        },
        pagination: {
            el: '.stocks-stories__pagination',
            clickable: true,
        },
        navigation: {
            nextEl: ".__stocks_stories__slider__btn_next",  
            prevEl: ".__stocks_stories__slider__btn_prev",
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const pagination = document.querySelector('.stocks-stories__pagination');
    const bullets = pagination.querySelectorAll('.swiper-pagination-bullet');
    if(bullets.length === 0) return;
    const observer = new MutationObserver(mutationRecords => {
        mutationRecords.forEach(mutation => {
            if (mutation.type === 'attributes' && mutation.attributeName === 'class') {
                onClassChange(mutation.target);
            }
        });
    });
    
    bullets.forEach(bullet => {
        observer.observe(bullet, {
            attributes: true,
            attributeFilter: ['class'],
        });
    });

    function onClassChange(target) {
        if(target.classList.contains('swiper-pagination-bullet-active')) {
            let activeIndex;
            bullets.forEach((bullet, index)=>{
                if(bullet == target) activeIndex = index;
            });

            observer.disconnect();

            bullets.forEach((unactive, index)=>{
                if(index < activeIndex) {
                    unactive.classList.add('previous-bullet');
                } else {
                    unactive.classList.remove('previous-bullet');
                }
            });

            bullets.forEach(bullet => {
                observer.observe(bullet, {
                    attributes: true,
                    attributeFilter: ['class'],
                });
            });
        };
    }
}