import { logoutFunc } from "./pages/profile/logout.js";
import { sendData, setCookie } from "./utils.js";

export function checkRefreshDate() {
    const expireCookie = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)expire_date\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
    const refreshToken = decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1"))

    if(!expireCookie || !refreshToken) return;
    const expireDateObj = new Date(expireCookie);

    const unixExpire = parseInt(expireDateObj.getTime() / 1000);
    const unixToday = parseInt(Date.now() / 1000);

    const threeDays = 259200;
    
    if(unixToday > unixExpire - threeDays && unixToday < unixExpire) {
        sendData({refresh: refreshToken},'/api/authentication/refresh-refresh-token/')
        .then(res=>{
            setCookie('expire_date', res.refresh_expire_date);
            setCookie('refresh_token', res.refresh);
        });
    } else if(unixToday > unixExpire) {
        logoutFunc();
    };
}