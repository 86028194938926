import { moveToOrder } from "./moveToOrder.js";
import { cdek } from "./cdek.js";
import { deliveryOrder } from "./deliveryOrder.js";
import { orderValidateInit } from "./orderValidation.js";
import { loyalty } from "./loyalty.js";

function init() {
    moveToOrder();
    cdek();
    deliveryOrder();
    orderValidateInit();
    loyalty();
}

export {
    init   
}