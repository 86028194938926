import { productCardAnimations } from "../../animations/productCardAnimations.js";
import { createCard } from "../../createCard.js";

export async function addCardsCatalog(data, section) {
    const fragment = document.createDocumentFragment();
    
    const promises = data.map(async (info)=>{
        const card = await createCard(info)
        fragment.appendChild(card);
    });

    Promise.all(promises).then(()=>{
        section.appendChild(fragment);
        productCardAnimations(section);
    });
}
