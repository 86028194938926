export function blogNav() {
    const blogNavBlock = document.querySelector('.__blog_navigation');
    if(!blogNavBlock) return;
    document.documentElement.style.scrollBehavior = 'smooth';

    const article = document.querySelector('article');
    const headings = article.querySelectorAll('h2');
    if(headings.length > 0) {
        const fragment = document.createDocumentFragment();
        headings.forEach((heading, index)=>{
            heading.id = `heading-id-${index}`;
            const link = document.createElement('a');
            link.classList.add('link', 'link_size_basic', 'link_subdued');
            link.innerText = heading.innerText;
            link.href = `#${heading.id}`;
            link.dataset.id = index;
            fragment.appendChild(link);
        });
        blogNavBlock.appendChild(fragment);

        let firstToActive = false;
        document.addEventListener('scroll',()=>{
            headings.forEach((heading, index)=>{
                const headLink = blogNavBlock.querySelector(`.link[data-id="${index}"]`)
                if(checkForView(heading) && !firstToActive) {
                    headLink.classList.add('link_active');
                    firstToActive = true;
                }
                if(!checkForView(heading) && headLink.classList.contains('link_active')) {
                    headLink.classList.remove('link_active');
                    firstToActive = false;
                }
            });
        }, {passive: true});
    }
}

function checkForView(section) {
    const sectionRect = section.getBoundingClientRect();
    if (sectionRect.top < window.innerHeight && sectionRect.bottom > 0) {
        return true;
    } else {
        return false;
    }
}