import WishlistLibrary from "../../libraries/WishlistLibrary.mjs";
import { wishlistBadge } from "../utils.js";

export function wishlistButtons() {
    const wishlistBtns = document.querySelectorAll('.__add_in_wishlist');
    wishlistBtns.forEach(addBtn=>{
        const productId = addBtn.dataset.id;
        const addBtnClass = new WishlistLibrary(addBtn,{
            product_id: productId,
            badge: wishlistBadge,
        });
    });
}