import { sendData, setCookie } from "../../utils.js";

export function logout() {
    const logoutBtn = document.querySelector('.__logout_btn');
    if(!logoutBtn) return;
    logoutBtn.addEventListener('click',()=>{
        logoutFunc();
    });
}

export function logoutFunc() {
    const refreshToken = document.cookie.replace(/(?:(?:^|.*;\s*)refresh_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
    if(!refreshToken) return;
    const body = {};
    body['refresh_token'] = refreshToken;
    
    sendData(body, '/api/logout/')
    .then(res=>{
        setCookie('access_token', '',{'max-age': -1});
        setCookie('refresh_token', '',{'max-age': -1});
        setCookie('expire_date', '',{'max-age': -1});

        setTimeout(() => {
            window.location.href = '/';
        }, 100);
    });
}

// Проверяй кол-во товаров в админке, при переносе в заказ товар резервируется и пропадает с сайта