import { getCurrentQuery, sendData } from "../../utils.js";
import { dispatchOrderValidate } from "./orderValidation.js";

export function deliveryOrder() {
    const radios = document.querySelectorAll('[name="delivery_way__selector"]');
    if(radios.length === 0) return;
    radios.forEach(radio=>{
        const dataAttr = radio.dataset.radio;
        const dataDisplay = document.querySelectorAll('[data-display]');
        radio.addEventListener('click',()=>{
            dataDisplay.forEach(display=>{
                if(display.dataset.display == dataAttr) {
                    display.dataset.state = 'active';
                } else {
                    display.dataset.state = 'disabled';
                };
            });
            dispatchOrderValidate();
        });
    });
    const addressSelectors = document.querySelectorAll('[name="delivery_address__selector"]');
    if(addressSelectors.length === 0) return;
    addressSelectors.forEach(selector=>{
        selector.addEventListener('click',()=>{
            dispatchOrderValidate();
            calculateOrderPrice(selector.dataset.city, '137');
        });
    });
}

export function calculateOrderPrice(address, tariff_code) {
    const queries = getCurrentQuery();
    let idQuery = null;
    if(queries.length > 0) {
        idQuery = getCurrentQuery()[0].order_id;
    }
    const body = {};
    body.address = address;
    body.tariff_code = tariff_code;
    sendData(body, `/api/order/${idQuery}/calculate-delivery-cost/`)
    .then(res=>{
        const deliveryPrice = document.querySelector('.__order_price__delivery');
        const totalPrice = document.querySelector('.__order_price__total');
        deliveryPrice.innerText = `${res.delivery_price}`;
        totalPrice.innerText = `${res.total_price}`;
    });
}