import * as main from './main/index.js';
import * as catalog from './catalog/index.js';
import * as brand from './brand/index.js';
import * as blog from './blog/index.js';
import * as stocks from './stocks/index.js';
import * as wishlist from './wishlist/index.js';
import * as basket from './basket/index.js';
import * as reviews from './reviews/index.js';
import * as profile from './profile/index.js';
import * as order from './order/index.js';
import * as cooperations from './cooperations/index.js';
import * as success from './success/index.js';
import * as calendar from './calendar/index.js';


main.init();
catalog.init();
brand.init();
blog.init();
stocks.init();
wishlist.init();
basket.init();
reviews.init();
profile.init();
order.init();
cooperations.init();
success.init();
calendar.init();