import { addCardsCatalog } from './pages/catalog/addCardsCatalog.js';
import { getCurrentQuery, getData } from './utils.js';
import { productCardAnimations } from './animations/productCardAnimations.js';
import { addReviewCards } from './pages/reviews/addReviewCards.js';
import { accordion } from './base/accordion.js';
import { addArticleCards } from './pages/articles/addArticleCards.js';

export function endlessPagination() {
    const buttons = document.querySelectorAll('.__endless_pagination__button');
    const section = document.querySelector('.__endless_pagination__section');
    if(buttons.length === 0 && !section) return;

    buttons.forEach(btn=>{
        const paginationAttr = btn.getAttribute('data-attr');
        const limitAttr = btn.dataset.limit;
        let currentPage = 1;
    
        if (paginationAttr) {
            const reloadedPageHref = new URL(window.location.href);
            reloadedPageHref.searchParams.set('page', 1);
            history.replaceState({}, '', reloadedPageHref.search.toString());

            const filters = getCurrentQuery();
            let filtersAll = '';
            filters.forEach(filter=>{
                if(!filter['page']) {
                    const [key, value] = Object.entries(filter)[0];
                    filtersAll += `&${key}=${value}`;
                };
            });

            let category = '';
            if(paginationAttr == 'products' && btn.dataset.category) {
                const getCategory = btn.dataset.category;
                category = `&category=${getCategory}&in_stock=1`;
            };

            getData(`/api/${paginationAttr}/?page=1${category}${filtersAll}`)
            .then(data => {
                if (data.next == null) btn.parentElement.removeChild(btn);
    
                if (paginationAttr == 'products') {
                    addCardsCatalog(data.results, section);
                    setTimeout(() => {
                        productCardAnimations(section);
                    }, 150);
                };

                if(paginationAttr == 'reviews') {
                    addReviewCards(data.results, section);
                    setTimeout(() => {
                        accordion(section);
                    }, 150);
                };

                if(paginationAttr == 'articles') {
                    addArticleCards(data.results, section);
                };
            })
            .catch(error=>{
                btn.parentElement.removeChild(btn);
            });
    
            btn.addEventListener('click', () => {
                currentPage++;
                const offset = (currentPage - 1) * limitAttr;
                const href = new URL(window.location.href);
                href.searchParams.set('page', currentPage.toString());
                history.replaceState({}, '', href.search.toString());
    
                getData(`/api/${paginationAttr}/?page=${currentPage}${category}${filtersAll}`)
                .then(data => {
                    if (data.next !== null) {
                        setNewPage();
                    } else {
                        btn.parentElement.removeChild(btn);
                    }
    
                    if (paginationAttr == 'products') {
                        addCardsCatalog(data.results, section);
                        productCardAnimations(section);
                    }
                    if(paginationAttr == 'reviews') {
                        addReviewCards(data.results, section);
                        accordion(section);
                    };
                    if(paginationAttr == 'articles') {
                        addArticleCards(data.results, section);
                    };
                });
            });
        }
    });
}

export function setNewPage() {
    const href = new URL(window.location.href);
    let pageValue = href.searchParams.get('page');
    if(pageValue !== null) {
        pageValue = parseInt(pageValue) + 1;
        href.searchParams.set('page', pageValue.toString());
    } else {
        href.searchParams.set('page', 2);
    }
    history.replaceState({}, '', href.search.toString());
}