import IMask from 'imask';

export function inputs() {

    var elementPhone = document.querySelectorAll('input[name="phone"]');
    var maskPhone = {
        mask: '+7 (000) 000-00-00',
        prepare: function (appended, masked) {
            if (appended === '8' && masked.value === '') {
                return '7';
            }
            return appended;
        },
    };
    elementPhone.forEach((el) => {
        new IMask(el, maskPhone);
    });

    const maskNumber = {
        mask: Number,
    };

    const numberInputs = document.querySelectorAll('.__input_num_only');
    if(numberInputs.length > 0) {
        numberInputs.forEach(input=>{
            new IMask(input, maskNumber);
        });
    };

    const maskTime = {
        overwrite: true,
        autofix: true,
        mask: 'HH:MM',
        blocks: {
            HH: {
                mask: IMask.MaskedRange,
                placeholderChar: 'HH',
                from: 0,
                to: 23,
                maxLength: 2
            },
            MM: {
                mask: IMask.MaskedRange,
                placeholderChar: 'MM',
                from: 0,
                to: 59,
                maxLength: 2
            }
        }
    };

    const timeInputs = document.querySelectorAll('.__time_input');
    if(timeInputs.length > 0) {
        timeInputs.forEach(input=>{
            new IMask(input, maskTime);
        });
    };

    const numberLabels = document.querySelectorAll('.numbers-label');
    if(numberLabels.length > 0) {
        numberLabels.forEach(label=>{
            const input = label.querySelector('input[type="text"]');
            const items = label.querySelectorAll('.numbers-label__item');

            new IMask(input, maskNumber);

            input.addEventListener('input',()=>{
                const value = input.value;
                const valueLength = input.value.length;
                const valueArray = value.split('');

                items.forEach((item, index)=>{
                    const newValue = valueArray[index];
                    item.classList.remove('animated');
                    if(!!newValue) {
                        item.classList.add('active');
                        item.innerHTML = newValue;
                    } else {
                        item.classList.remove('active');
                        item.innerHTML = '_';
                    };
                });

                const currentItem = items[valueLength];

                if(!!currentItem && currentItem.innerHTML == '_') {
                    currentItem.classList.add('animated');
                    currentItem.classList.add('active');
                };
            });
            input.addEventListener('focus',()=>{
                const valueLength = input.value.length;
                items.forEach((item, index)=>{
                    if(index == valueLength) {
                        item.classList.add('active');
                        item.classList.add('animated');
                    };
                });
            });
            input.addEventListener('blur',()=>{
                items.forEach(item=>{
                    if(item.innerHTML == '_') {
                        item.classList.remove('active');
                        item.classList.remove('animated');
                    };
                });
            });
        });
    };

    const cardInput = document.querySelector('[name="pan"]');
    const maskCreditCard = {
        mask: '0000 0000 0000 0000'
    };
    if(cardInput) {
        new IMask(cardInput, maskCreditCard);
    };
    const cvvCardInput = document.querySelector('[name="cvv"]');
    const maskCVV = {
        mask: '000'
    };
    if(cvvCardInput) {
        new IMask(cvvCardInput, maskCVV);
    };
    const cardDateInput = document.querySelector('[name="exp_date"]');
    const maskCardDate = {
        mask: 'DD/YY',
        blocks: {
            DD: {
                mask: IMask.MaskedRange,
                placeholderChar: 'DD',
                from: 0,
                to: 31,
                maxLength: 2
            },
            YY: {
                mask: IMask.MaskedRange,
                placeholderChar: 'YY',
                from: 0,
                to: 99,
                maxLength: 2
            }
        }
    };
    if(cardDateInput) {
        new IMask(cardDateInput, maskCardDate);  
    };

}

