import { deleteData, setCookie, userLoggedIn } from "../../utils.js";

export function clearWishlist() {
    const clearBtn = document.querySelector('.__wishlist_clear__btn');
    if(!clearBtn) return;
    clearBtn.addEventListener('click',()=>{
        if(userLoggedIn()) {
            deleteData('/api/wishlist/flush/')
            .then(res=>{
                window.location.reload();
            });
        } else {
            setCookie('wishlist', '');
            setTimeout(() => {
                window.location.reload();
            }, 100);
        };
    });
}