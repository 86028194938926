import { deleteProfile } from "./deleteProfile.js";
import { profileValidation } from "./profileValidation.js";
import { logout } from "./logout.js";
import { profileSearch } from "./profileSearch.js";
import { periodFilters } from "./periodFilters.js";

function init() {
    profileSearch();
    deleteProfile();
    logout();
    profileValidation();
    periodFilters();
}

export {
    init   
}