import { debounce } from './utils.js';
export function counter() {
    const counters = document.querySelectorAll('.counter');
    if(counters.length === 0) return;
    counters.forEach(counter=>{
        const buttons = counter.querySelectorAll('.__counter__btn');
        const input = counter.querySelector('.__counter__input');

        const step = parseInt(counter.dataset.step);

        buttons.forEach(btn=>{
            btn.addEventListener('click',()=>{
                const parsedValue = parseInt(input.value);

                let newValue;
                if(btn.dataset.sign == 'plus') {
                    newValue = parsedValue + step;
                } else if(btn.dataset.sign == 'minus' && parsedValue >= step) {
                    newValue = parsedValue - step;
                };
                dispatcher(input, newValue);
            });
        });

        input.addEventListener('input',()=>{
            input.value = input.value.replace(/[^0-9кг]/g, '');
            const kgMatch = input.value.match(/[кг]/g);
            if (kgMatch && kgMatch.length > 2) {
                input.value = input.value.slice(0, -1);
            };
            debouncedCheckEmpty(input);
            debouncedRounding(input, step);
        });

    });
}

const debouncedCheckEmpty = debounce(checkEmpty, 500);
const debouncedRounding = debounce(rounding, 500);

function rounding(input, step) {
    const parsedValue = parseInt(input.value);
    input.value = parseInt(parsedValue / step) * step + 'кг';
}

function checkEmpty(input) {
    input.value = input.value.replace(/[^0-9]/g, '');
    if(input.value == '') {
        input.value = '0кг';
    } else if(!input.value.includes('кг')) {
        input.value = input.value + 'кг';
    };
}

function dispatcher(input, newValue) {
    let value = parseInt(newValue);
    if (!isNaN(value)) {
        input.value = value + 'кг';
    } else {
        input.value = '0кг';
    };
    const event = new Event("input", {bubbles: true});
    input.dispatchEvent(event);
}