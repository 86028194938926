export function profileValidation() {
    const form = document.querySelector('#change-user-info');
    const radioBlock = document.querySelector('.user-card-default__radio');
    if(!form || !radioBlock) return;
    const formRadios = form.querySelectorAll('input[type="radio"]');
    const radioBlockRadios = radioBlock.querySelectorAll('input[type="radio"]');

    const allRadios = [...formRadios, ...radioBlockRadios];

    if(allRadios.length > 0) {
        allRadios.forEach(selector=>{
            selector.addEventListener('click',()=>{
                dispatchProfileValidate();
            });
        });
    };

    const phoneInput = document.querySelector('#user-change-phone-input');
    if(!phoneInput) return;
    document.addEventListener('DOMContentLoaded',()=>{
        let phoneValue = phoneInput.value;
        phoneInput.addEventListener('input',()=>{
            if(phoneInput.value !== phoneValue) {
                form.dataset.changePhone = 'true';
            } else {
                delete form.dataset.changePhone;
            };
        });
    });
}

function dispatchProfileValidate() {
    const form = document.querySelector('#change-user-info');
    const inputs = Object.keys(getBody(form))
        .map(el => form.elements[`${el}`])
        .filter(el => el.type === 'text' || el.type === 'textarea' || el.type === 'password');
    inputs.forEach(input=>{
        input.value = input.value;
        input.dispatchEvent(new Event("input"));
    });
}

function getBody(form) {
    const formData = new FormData(form);
    const body = {};
    for (let [name, value] of formData.entries()) {
        body[name] = value;
    }
    return body;
}