import { debounce, getCurrentQuery, sendData } from "../../utils.js";

export function loyalty() {
    const input = document.querySelector('#order-loyalty-input');
    if(!input) return;
    const queries = getCurrentQuery();
    
    const writeOff = document.querySelector('#write-off');
    const notWriteOff = document.querySelector('#not-write-off');
    const writeAll = document.querySelector('#write-all');
    const maxAvailable = document.querySelector('.__max_available__amount');
    const orderForm = document.querySelector('#order-form');

    let idQuery = null;
    if(queries.length > 0) {
        idQuery = queries[0].order_id;
    };
    input.addEventListener('input',()=>{
        debouncedGet(input.value);
        if(input.value == '' || input.value == '0') {
            notWriteOff.click();
        } else {
            writeOff.click();
        };
        if(parseInt(input.value) == parseInt(maxAvailable.innerText)) {
            writeAll.checked = true;
        } else {
            writeAll.checked = false;
        };
        orderForm.dataset.bonus_amount = input.value;
    });

    notWriteOff.addEventListener('click',()=>{
        input.value = '0';
        input.dispatchEvent(new Event("input"));
    });
    writeAll.addEventListener('click',()=>{
        input.value = parseInt(maxAvailable.innerText);
        input.dispatchEvent(new Event("input"));
        writeOff.click();
    });

    const debouncedGet = debounce(getAvailableAmount, 500);

    function getAvailableAmount(value) {
        const body = {};
        body['amount'] = value; 
        sendData(body, `/api/order/${idQuery}/get-available-amount/`)
        .then(res=>{
            input.classList.remove('error');
        })
        .catch(error=>{
            input.classList.add('error');
        });
    };
}