import { debounce, getCurrentQuery } from "./utils.js";

export function filters() {
    const filterElems = document.querySelectorAll('.__filter_elem');

    if(filterElems.length > 0) {
        filterElems.forEach(filter=>{
            const activeQueries = getCurrentQuery();
            const activeKey = filter.dataset.query;
            const activeValue = filter.dataset.value;
    
            const clickedFilterFamily = document.querySelectorAll(`.__filter_elem[data-query="${activeKey}"]`);
            const clickedFilterAll = document.querySelectorAll(`.__filter_elem[data-query="${activeKey}"][data-value=""]`);
    
            activeQueries.forEach(query=>{
                if(query[`${activeKey}`] == activeValue){
                    clickedFilterAll.forEach(filterAll=>{
                        filter == filterAll ? filterAll.checked = true : filterAll.checked = false;
                    });
                    filter.checked = true;
                };
            });
    
            filter.addEventListener('click',()=>{
                clickedFilterAll.forEach(filterAll=>{
                    if(filter == filterAll) {
                        clickedFilterFamily.forEach(familyElem=>familyElem.checked = false);
                        filter.checked = true;
                    } else {
                        filterAll.checked = false;
                    }
                });
                if(filter.classList.contains('debounced')) {
                    const filterArray = [];
                    filterElems.forEach(elem=>{
                        if(elem.checked) {
                            const activeKey = elem.dataset.query;
                            const activeValue = elem.dataset.value;
                            filterArray.push({
                                "key": activeKey,
                                "value": activeValue
                            });
                        }
                    });
                    debouncedFilterWithArray(filterArray);
                };
            });
        });
    }

    const openMenuBtn = document.querySelector('.__filters_in_menu__btn');
    const filterMenu = document.querySelector('.__filters_in_menu__menu');
    if(openMenuBtn && filterMenu) {
        let blockInMenu;
        let menuBtnText = '';
        if(window.innerWidth > 769) {
            blockInMenu = filterMenu.querySelector('.filters-block__menu__filters');
        } else {
            blockInMenu = filterMenu.querySelector('.filters-block__menu__filters_mobile');
        }
        blockInMenu.querySelectorAll('.__filter_elem').forEach(elem=>{
            if(elem.checked && elem.dataset.value !== '') {
                const checkedLabel = document.querySelector(`label[for="${elem.id}"]`);
                menuBtnText = menuBtnText == '' ? checkedLabel.innerText : menuBtnText + ' , ' + checkedLabel.innerText;
            }
        })
        if(menuBtnText !== '') openMenuBtn.querySelector('span').innerText = menuBtnText;
        

        openMenuBtn.addEventListener('click',()=>{
            if(filterMenu.dataset.menuState == 'close') {
                filterMenu.dataset.menuState = 'open';
                openMenuBtn.classList.add('link_active');
                setTimeout(() => {
                    document.addEventListener('click',closefilterMenu);
                }, 100);
            }
        })
    }

    
    function closefilterMenu(event) {
        if(!filterMenu.contains(event.target)) {
            filterMenu.dataset.menuState = 'close';
            openMenuBtn.classList.remove('link_active');
            document.removeEventListener('click',closefilterMenu);
        }
    }


    const filterConfirmElems = document.querySelectorAll('.__filter_elem__confirm');
    if(filterConfirmElems.length === 0) return;
    filterConfirmElems.forEach(confirmElem=>{
        confirmElem.addEventListener('click',()=>{
            let filtersArray = [];
            filterElems.forEach(elem=>{
                const clickedKey = elem.getAttribute('data-query');
                const clickedValue = elem.getAttribute('data-value');
                const filterObject = {};
                if(elem.checked) {
                    filterObject.key = clickedKey;
                    filterObject.value = clickedValue;
                    filtersArray.push(filterObject);
                };
            });
            filterWithArray(filtersArray);
        });
    });

    const filterCancelElems = document.querySelectorAll('.__filter_elem__cancel');
    if(filterCancelElems.length === 0) return;
    filterCancelElems.forEach(cancelElem=>{
        cancelElem.addEventListener('click',()=>{
            let filtersArray = [];
            filterWithArray(filtersArray);
        });
    });
}

const removeDuplicates = (arr) => {
    const seen = new Set();
    
    return arr.filter(item => {
      const key = JSON.stringify(item);
      if (seen.has(key)) {
        return false;
      }
      seen.add(key);
      return true;
    });
  };


const debouncedFilterWithArray = debounce(filterWithArray, 500);

function filterWithArray(Array) {
    const url = new URLSearchParams();
    const uniqueArray = removeDuplicates(Array);
    uniqueArray.forEach(elem=>{
        url.append(elem.key, elem.value);
    });
    window.location.search = url.toString();
}