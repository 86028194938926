import { sendData } from "../../utils.js";

export function moveToOrder() {
    const goToOrderBtn = document.querySelector('.__go_to_order__btn');
    if(!goToOrderBtn) return;
    goToOrderBtn.addEventListener('click',()=>{
        const basketList = document.querySelector('.__basket_append');
        const basketCheckboxes = basketList.querySelectorAll('.__select_card__basket');

        const body = {};
        let orderBody = [];
        const promises = Array.from(basketCheckboxes).map(checkbox=>{
            if(!checkbox.checked) return;
            orderBody.push({
                'product_id': parseInt(checkbox.dataset.id),
                'quantity': 1
            });
        });
        Promise.all(promises).then(()=>{
            if(orderBody.length === 0) return;
            body.products = orderBody;
            sendData(body, '/api/order/move-to-order/')
            .then(res=>{
                window.location.href = `/order/edit-order/?order_id=${res.order_id}`;
            });
        });
    });
};