export function downloadMobileApp() {
    const downloadBtn = document.querySelector('.__mobile_app__download_btn');
    const downloadAppSection = document.querySelector('.__mobile_app__download_section');
    const pathname = window.location.pathname;
    const isDownloadPage = pathname == '/download-app/';
    if (!downloadBtn && !downloadAppSection && isDownloadPage) return;

    const os = getOS();


    if (isDownloadPage) {
        if (os == 'iOS' || os == 'Mac OS') {
            window.location.href = 'https://apps.apple.com/ru/app/%D0%BC%D0%B5%D0%B3%D0%B0%D1%85%D0%B5%D0%BD%D0%B4/id6447340687';
        } else {
            window.location.href = 'https://play.google.com/store/apps/details?id=com.evothings.mhand&hl=ru';
        }
    } else if (downloadAppSection) {
        if (os == 'iOS' || os == 'Mac OS') {
            window.location = 'https://apps.apple.com/ru/app/%D0%BC%D0%B5%D0%B3%D0%B0%D1%85%D0%B5%D0%BD%D0%B4/id6447340687';
        } else {
            window.location = 'https://play.google.com/store/apps/details?id=com.evothings.mhand&hl=ru';
        }
    } else if (downloadBtn) {
        if (os == 'iOS' || os == 'Mac OS') {
            downloadBtn.href = 'https://apps.apple.com/ru/app/%D0%BC%D0%B5%D0%B3%D0%B0%D1%85%D0%B5%D0%BD%D0%B4/id6447340687';
        } else {
            downloadBtn.href = 'https://play.google.com/store/apps/details?id=com.evothings.mhand&hl=ru';
        }
    }
};

function getOS() {
    let userAgent = window.navigator.userAgent;
    let platform = window.navigator?.userAgentData?.platform || window.navigator.platform;
    let os = null;

    // Определение ОС
    if (/Mac/i.test(platform)) {
        os = 'Mac OS';
    } else if (/Win/i.test(platform)) {
        os = 'Windows';
    } else if (/Linux/i.test(platform)) {
        os = 'Linux';
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
        os = 'iOS';
    } else if (/Android/i.test(userAgent)) {
        os = 'Android';
    }

    return os;
}