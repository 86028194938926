import { getWishlistFromCookie } from "../../../libraries/WishlistLibrary.mjs";
import { userLoggedIn } from "../../utils.js";

export function checkForEmpty() {
    const wishlistSections = document.querySelectorAll('[data-wishlist]');
    if(wishlistSections.length === 0) return;
    const wishlist = getWishlistFromCookie();
    if(wishlist.length > 0 && !userLoggedIn()) {
        wishlistSections.forEach(section=>{
            section.dataset.wishlist = 'full';
        });
    } else if (wishlist.length == 0 && !userLoggedIn()) {
        wishlistSections.forEach(section=>{
            section.dataset.wishlist = 'clear';
        });
    } else if (userLoggedIn()) {
        const wishlistList = document.querySelector('.__wishlist_append');
        let dataWishlist = 'clear';
        if(wishlistList.querySelector('.product-card')) dataWishlist = 'full';
        wishlistSections.forEach(section=>{
            section.dataset.wishlist = `${dataWishlist}`;
        });
    }
}