import { refreshAccessToken, setCookie, userLoggedIn } from "../../utils.js";

let token = userLoggedIn();

async function getPublicKey() {
    const response = await fetch('/api/rsa-key/get-key/', {
        headers: {
            'Authorization': `Bearer ${token}`,
        },
    });
    if (response.ok) {
        return response.json();
    } else if(response.status === 401 && token) {
        try {
            token = await refreshAccessToken();
            setCookie('access_token', token);
            return getPublicKey(token);
        } catch (error) {
            throw new Error('Failed to refresh token');
        }
    };
    const data = await response.json();
    return data.public_key;
}

function pemToArrayBuffer(pem) {
    const b64 = pem.public_key.replace(/-----BEGIN PUBLIC KEY-----/, '')
        .replace(/-----END PUBLIC KEY-----/, '')
        .replace(/\s/g, '');
    const binary = atob(b64);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
}

async function importPublicKey(pem) {
    const binaryDer = pemToArrayBuffer(pem);
    
    return await window.crypto.subtle.importKey(
        'spki',
        binaryDer,
        {
            name: 'RSA-OAEP',
            hash: 'SHA-256',
        },
        false,
        ['encrypt']
    );
}

function arrayBufferToBase64(buffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
        binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
}

async function encryptData(publicKey, dataObject) {
    const jsonData = JSON.stringify(dataObject);
    const encoder = new TextEncoder();
    const encodedData = encoder.encode(jsonData);

    const encrypted = await window.crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
        },
        publicKey,
        encodedData
    );
    return arrayBufferToBase64(encrypted);
}

async function sendEncryptedData(dataObject) {
    const publicKeyPem = await getPublicKey();
    const publicKey = await importPublicKey(publicKeyPem);

    const encryptedEntries = await Promise.all(
        Object.entries(dataObject).map(async ([key, value]) => {
            const encryptedValue = await encryptData(publicKey, value);
            return [key, encryptedValue];
        })
    );

    const newData = Object.fromEntries(encryptedEntries);

    const response = await fetch('/api/card/attach/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(newData),
    });

    const result = await response.json();
    return result;

}

export async function handleBankCardSubmit(data) {
    // const data = {
    //     pan: "4300 0000 0000 0777",
    //     exp_date: "12/30",
    //     card_holder: "iVan",
    //     cvv: 111,
    // };

    try {
        const result = await sendEncryptedData(data);
        console.log('Дешифрованные данные от сервера:', result.decrypted_data);
    } catch (error) {
        console.error('Ошибка при шифровании или отправке данных:', error);
    }
}