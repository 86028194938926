export function addArticleCards(data, section) {
    const fragment = document.createDocumentFragment();
    data.forEach(elem=>{
        const card = document.createElement('a');
        card.classList.add('blog-card');
        card.href = `/a/${elem.slug}`;

        const photo = elem.image ? elem.image : 'static/assets/stub.webp';

        let tags = '';
        if(elem.tags && elem.tags.length > 0) {
            elem.tags.forEach(tag=>{
                tags = tags + `<span>${tag.name}</span>`
            });
        };
        card.innerHTML = `
            <div class="blog-card__image-block">
                <img src="${photo}" alt="blog image">
            </div>
            <div class="blog-card__info-block">
                <div class="blog-card__title-block">
                    <div class="blog-card__title-block__tags">
                        ${tags}
                        <span>${formatDate(elem.publish_at)}</span>
                    </div>
                    <div class="blog-card__title-block__title">
                        ${elem.name}
                    </div>
                </div>
                <div class="blog-card__info-block__text">
                    ${elem.content}
                </div>
            </div>
        `;

        fragment.appendChild(card);
    });
    section.appendChild(fragment);
}

function formatDate(date) {
    const splitDate = date.split('T')[0].split('-');
    const months = [
        'Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'
    ];
    const formatedDate = `${splitDate[2]} ${months[parseInt(splitDate[1]) - 1]} ${splitDate[0]}`;
    return formatedDate;
}