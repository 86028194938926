import { getRem } from "./utils.js";

export function slider() {
    const calendarMenu = new Swiper('.__calendar_menu__slider',{
        slidesPerView: 1,
        breakpoints: {
            769: {
                slidesPerView: 1,
            }
        },
        navigation: {
            nextEl: ".__calendar_menu__slider__btn_next",  
            prevEl: ".__calendar_menu__slider__btn_prev"
        },
        mousewheel: {
            forceToAxis: true
        }
    });
    
    const mainHeroBanner = new Swiper('.__main_hero__banner__slider',{
        slidesPerView: 1,
        breakpoints: {
            769: {
                spaceBetween: getRem() * 1.75,
                slidesPerView: 1.667,
            }
        },
        navigation: {
            nextEl: ".__main_hero__banner__slider__btn_next",  
            prevEl: ".__main_hero__banner__slider__btn_prev"
        },
        mousewheel: {
            forceToAxis: true
        }
    });
    
    const mainHeroChapter = new Swiper('.__main_hero__chapter__slider',{
        spaceBetween: getRem() * 1.25,
        slidesPerView: 1.5,
        breakpoints: {
            769: {
                spaceBetween: getRem() * 1.75,
                slidesPerView: 5,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const mainHeroBrands = new Swiper('.__main_brands__slider',{
        spaceBetween: getRem() * 1.25,
        slidesPerView: 2.5,
        navigation: {
            prevEl: '.__main_brands__slider__btn_prev',
            nextEl: '.__main_brands__slider__btn_next'
        },
        breakpoints: {
            769: {
                spaceBetween: getRem() * 1.75,
                slidesPerView: 5,
            }
        },
        mousewheel: {
            forceToAxis: true
        }
    });

    const productCardMobile = new Swiper('.__product_card__slider_mobile',{
        spaceBetween: getRem() * .5,
        slidesPerView: 1.085,
        slidesOffsetBefore: getRem() * .5,
        slidesOffsetAfter: getRem() * .5,
        mousewheel: {
            forceToAxis: true
        }
    });

    const calendarMobile = new Swiper('.__calendar_mobile__slider_mobile',{
        slidesPerView: 1,
        navigation: {
            nextEl: ".__calendar_mobile__slider_mobile__btn_next",  
            prevEl: ".__calendar_mobile__slider_mobile__btn_prev"
        },
        mousewheel: {
            forceToAxis: true
        }
    });
}


// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { slider } from "./путь/к/файлу/slider.js";

// Активация: slider();