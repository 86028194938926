import { setCookie, userLoggedIn } from "../../utils.js";

export function deleteProfile() {
    const deleteBtn = document.querySelector('.__account_delete__btn');
    if(!deleteBtn) return;
    deleteBtn.addEventListener('click',()=>{
        deleteData('/api/profile/delete/')
        .then(res=>{
            setCookie('access_token', '',{'max-age': -1});
            setCookie('refresh_token', '',{'max-age': -1});
            setCookie('expire_date', '',{'max-age': -1});

            setTimeout(() => {
                window.location.href = '/?acc_deleted=1';
            }, 100);
        });
    });
}
async function deleteData(url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(!userLoggedIn()) return;
    const response = await fetch(url, {
        method: "DELETE",
        headers: {
            'Content-Type': "application/json",
            'X-CSRFToken': csrfToken,
            'Authorization': 'Bearer ' + userLoggedIn(),
        },
    });
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};