export function accordion(section) {
    const accordionItems = section ? section.querySelectorAll('.accordion-item') : document.querySelectorAll('.accordion-item');
    if(accordionItems.length === 0) return;
    accordionItems.forEach(item => {
        if(item.classList.contains('accordion-item_header-click')) {
            const header = item.querySelector('.accordion-item__header');
            if(!header) return;
            header.addEventListener('click', () => {
                accordionFunctionality(item);
                if(!header.dataset.state) {
                    header.dataset.state = 'active';
                } else {
                    header.dataset.state = '';
                }
            });
        } else {
            item.addEventListener('click', () => {
                accordionFunctionality(item)
            });
        }
    });
    function accordionFunctionality(item) {
        const attr = item.dataset.family;
        const accordionFamily = section ? section.querySelectorAll(`.accordion-item[data-family="${attr}"]`) : document.querySelectorAll(`.accordion-item[data-family="${attr}"]`);
        const findBodyElem = item.querySelector('.accordion-item__body');
        let otherIsActive = false;
        if(accordionFamily.length > 0) {
            accordionFamily.forEach(el=>{
                if(el !== item && el.classList.contains('show')) {
                    const elBody = el.querySelector('.accordion-item__body');

                    el.classList.remove('show');
                    elBody.style.maxHeight = null;

                    otherIsActive = true;
                }
            });
        } else {
            accordionItems.forEach(el=>{
                if(el !== item && el.classList.contains('show')) {
                    const elBody = el.querySelector('.accordion-item__body');

                    el.classList.remove('show');
                    elBody.style.maxHeight = null;

                    otherIsActive = true;
                }
            });
        }
        if(item.classList.contains('show') && !otherIsActive) {
            item.classList.remove('show');
            findBodyElem.style.maxHeight = null;
        } else if(!item.classList.contains('show')) {
            item.classList.add('show');
            findBodyElem.style.maxHeight = findBodyElem.scrollHeight + "px";
        }
    }
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { accordion } from "./путь/к/файлу/accordion.js";

// Активация: accordion();