import { getBasketFromCookie } from "../libraries/BasketLibrary.mjs";
import { getWishlistFromCookie } from "../libraries/WishlistLibrary.mjs";
import { z } from "../libraries/zod.js";
import { closeAllModals, openModal } from "./base/modals.js";
import {
  codeMenuFunctionary,
  openHeaderMenu,
  subheadingFixed,
} from "./header/headerMenus.js";
import { createAddressLabel } from "./pages/order/createAddressLabel.js";
import { dispatchOrderValidate } from "./pages/order/orderValidation.js";
import { handleBankCardSubmit } from "./pages/profile/addBankCard.js";
import {
  formatDate,
  getCurrentQuery,
  getFile,
  patchData,
  sendData,
  setCookie,
  userLoggedIn,
} from "./utils.js";

function getTextInsideSquareBrackets(text) {
  const startIndex = text.indexOf("["); // Находим индекс первой открывающей скобки
  const endIndex = text.lastIndexOf("]"); // Находим индекс последней закрывающей скобки
  if (startIndex !== -1 && endIndex !== -1 && startIndex < endIndex) {
    // Проверяем, что скобки найдены и первая скобка находится перед последней
    return text.substring(startIndex + 1, endIndex); // Возвращаем текст между первой и последней скобками
  } else {
    return ""; // Если скобки не найдены или первая скобка находится после последней, возвращаем пустую строку
  }
}

const schemaRegister = z
  .object({
    email: z.string().email({ message: "Invalid email address" }),
    password: z.string().min(4, { message: "слишком коротко" }),
    repeat_password: z.string().min(4, { message: "слишком коротко" }),
  })
  .refine((data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password", "repeat_password"], // path of error
  });
const schemaNewPass = z
  .object({
    password: z.string().min(4, { message: "слишком коротко" }),
    repeat_password: z.string().min(4, { message: "слишком коротко" }),
  })
  .refine((data) => data.password === data.repeat_password, {
    message: "Passwords don't match",
    path: ["password", "repeat_password"], // path of error
  });
const schemaLogin = z.object({
  phone: z.string().min(18, { message: "Неверное количество символов" }),
});
const schemaCallBack = z.object({
  // email: z.string()
  //     .email({ message: "Invalid email address" }),
  phone: z.string().min(18),
  first_name: z.string().min(1),
  // last_name: z.string(),
  message: z.string(),
});
const schemaHelp = z.object({
  phone: z.string().min(18),
  first_name: z.string().min(1),
  email: z.string().email(),
  city: z.string().min(1),
});
const schemaReview = z.object({
  name: z.string().min(1),
  message: z.string().min(1),
});
const schemaCode = z.object({
  code: z.string().min(4).max(4),
  phone: z.string().min(18),
});
const schemaUserInfo = z.object({
  email: z.string().email({ message: "Invalid email address" }),
  phone: z.string().min(18),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
});
const schemaAddUserInfo = z.object({
  email: z
    .string()
    .email({ message: "Введите Вашу почту" })
    .optional()
    .or(z.literal("")),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
});
const schemaAddress = z.object({
  city: z.string().min(1),
  street: z.string(),
  // apartment: z.string(),
  house: z.string().min(1),
});
const wholesalersSchema = z.object({
  phone: z.string().min(18),
  first_name: z.string().min(1),
});
const distributionSchema = z.object({
  email: z.string().email({ message: "Invalid email address" }),
});
const getMoneySchema = z.object({
  phone: z.string().min(18),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
});
const orderSchema = z.object({
  phone: z.string().min(18),
  email: z.string().email({ message: "Invalid email address" }),
  first_name: z.string().min(1),
  last_name: z.string().min(1),
});
const vacancySchema = z.object({
  // city: z.string(),
  name: z.string().min(1),
  phone: z.string().min(18),
  birthday: z.string(),
  citizenship: z.string(),
});
const refundSchema = z.object({
  comment: z.string().min(1),
});
const addCardSchema = z.object({
  pan: z.string().min(19),
  exp_date: z.string().min(5),
  // card_holder: z.string(),
  cvv: z.string().min(3),
});

export function validation() {
  const loginForm = document.querySelector("#login-menu-form");
  if (loginForm) {
    formValidate(loginForm, schemaLogin, (body) => {
      const codeFormPhone = document.querySelector("#code-form-login-phone");
      loginForm.button.setAttribute("disabled", "disabled");
      sendData(body, "/api/authentication/get-code/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else if (res.detail) {
          dialogAnimate(
            "error",
            "Вы привысили кол-во попыток, попробуйте позже"
          );
        } else if (res.message) {
          codeFormPhone.value = body.phone;
          codeFormPhone.focus();
          clearForm(loginForm);
          openHeaderMenu("code-login");
          subheadingFixed();
        }
      });
    });
  }
  const codeForm = document.querySelector("#code-login-form");
  if (codeForm) {
    formValidate(codeForm, schemaCode, (body) => {
      const codeMenuInput = document.querySelector("#code-menu-login-input");
      sendData(body, "/api/authentication/confirm-code/").then((res) => {
        if (res.error) {
          codeMenuInput.classList.add("error");
          codeForm.elements.button.disabled = true;
          dialogAnimate("error", res.error);
        } else {
          const basket = getBasketFromCookie();
          const wishlist = getWishlistFromCookie();
          setCookie("expire_date", res.expire_date);
          setCookie("access_token", res.access_token);
          setCookie("refresh_token", res.refresh_token);
          const basketPromises = basket.map((item) => {
            sendData(item, "/api/basket/add/");
          });
          const wishlistPromises = wishlist.map((item) => {
            sendData(item, "/api/wishlist/add/");
          });
          Promise.all(basketPromises).then((result) => {
            setCookie("basket", "", { "max-age": -1 });
          });
          Promise.all(wishlistPromises).then((result) => {
            setCookie("wishlist", "", { "max-age": -1 });
          });
          const NextPage = getCurrentQuery().find((item) => item.next)?.next;

          if (NextPage) {
            window.location.href = NextPage;
          } else {
            window.location.href = "/profile/";
          }
        }
      });
    });
  }
  const reviewForm = document.querySelector("#review-form");
  if (reviewForm) {
    formValidate(reviewForm, schemaReview, (body) => {
      const info = {
        ...body,
      };
      const ratingValue = document.querySelector(
        '[name="review-modal-selector"]:checked'
      );
      if (ratingValue) info.rating = parseInt(ratingValue.dataset.value);
      sendData(info, "/api/reviews/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.href = "/success/?scenario=review";
        }
      });
    });
  }
  const helpForm = document.querySelector("#help-form");
  if (helpForm) {
    formValidate(helpForm, schemaHelp, (body) => {
      sendData(body, "/api/feedback/faq/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.href = "/success/";
        }
      });
    });
  }
  const updUserInfoForm = document.querySelector("#change-user-info");
  if (updUserInfoForm) {
    formValidate(updUserInfoForm, schemaUserInfo, (body) => {
      const info = {
        ...body,
      };
      const gender = document.querySelector('[name="select-sex"]:checked');
      const birthdayInput = updUserInfoForm.querySelector('[name="birthday"]');
      if (!!gender) info["gender"] = gender.dataset.gender;
      if (birthdayInput.value == "") {
        info.birthday = null;
      } else {
        info.birthday = formatDate(birthdayInput.value);
      }

      const cityInput = updUserInfoForm.querySelector('[name="city"]');
      if (cityInput.dataset.attr !== "")
        info.city = parseInt(
          cityInput.closest(".__select__title").dataset.attr
        );

      patchData(info, "/api/profile/update/")
        .then((res) => {
          if (res.error) {
            dialogAnimate("error", res.error);
          } else {
            dialogAnimate("upd_user_info_dialog");
            updUserInfoForm.elements.button.disabled = true;
            updUserInfoForm.dataset.state = "disabled";
            setTimeout(() => {
              updUserInfoForm.dataset.state = null;
            }, 5000);
          }
        })
        .catch((err) => {
          if (err) {
            let errMessage = "Произошла ошибка";
            if (err.data.detail) {
              errMessage = err.data.detail;
            } else if (err.data.error) {
              errMessage = err.data.error;
            } else {
              console.log(err.data);
            }
            dialogAnimate("error", errMessage);
          }
        });

      if (
        updUserInfoForm.dataset.changePhone &&
        updUserInfoForm.dataset.changePhone == "true"
      ) {
        sendData(
          { phone: body.phone },
          "/api/profile-phone/request-update-number/"
        )
          .then((res) => {
            if (error) {
              dialogAnimate("error", res.error);
            } else {
              const phoneInput = document.querySelector(
                "#code-form-change-phone-phone"
              );
              phoneInput.value = body.phone;
              openModal("change-phone");
              codeMenuFunctionary(
                "change-phone",
                "/api/profile-phone/request-update-number/"
              );
            }
          })
          .catch((err) => {
            dialogAnimate("error", Object.values(err.data)[0][0]);
          });
      }
    });
  }
  const addUserInfoForm = document.querySelector("#update-user-data-form");
  if (addUserInfoForm) {
    formValidate(addUserInfoForm, schemaAddUserInfo, (body) => {
      const info = {
        ...body,
      };
      const birthdayInput = addUserInfoForm.querySelector('[name="birthday"]');
      if (birthdayInput.value == "") {
        info.birthday = null;
      } else {
        info.birthday = formatDate(birthdayInput.value);
      }

      const cityInput = addUserInfoForm.querySelector('[name="city"]');
      if (cityInput.dataset.attr !== "")
        info.city = parseInt(
          cityInput.closest(".__select__title").dataset.attr
        );

      patchData(info, "/api/profile/update/")
        .then((res) => {
          if (res.error) {
            dialogAnimate("error", res.error);
          } else {
            setCookie("needGetFullInfo", "false");
            const NextPage = getCurrentQuery().find((item) => item.next)?.next;
            if (NextPage) {
              window.location.href = NextPage;
            } else {
              window.location.href = "/profile/";
            }
          }
        })
        .catch((err) => {
          if (err) {
            let errMessage = "Произошла ошибка";
            if (err.data.detail) {
              errMessage = err.data.detail;
            } else if (err.data.error) {
              errMessage = err.data.error;
            } else {
              console.log(err.data);
            }
            dialogAnimate("error", errMessage);
          }
        });

      if (
        addUserInfoForm.dataset.changePhone &&
        addUserInfoForm.dataset.changePhone == "true"
      ) {
        sendData(
          { phone: body.phone },
          "/api/profile-phone/request-update-number/"
        )
          .then((res) => {
            if (error) {
              dialogAnimate("error", res.error);
            } else {
              const phoneInput = document.querySelector(
                "#code-form-change-phone-phone"
              );
              phoneInput.value = body.phone;
              openModal("change-phone");
              codeMenuFunctionary(
                "change-phone",
                "/api/profile-phone/request-update-number/"
              );
            }
          })
          .catch((err) => {
            dialogAnimate("error", Object.values(err.data)[0][0]);
          });
      }
    });
  }
  const changePhoneModalForm = document.querySelector(
    "#code-change-phone-modal-form"
  );
  if (changePhoneModalForm) {
    formValidate(changePhoneModalForm, schemaCode, (body) => {
      sendData(body, "/api/profile-phone/confirm-update-number/").then(
        (res) => {
          if (res.error) {
            dialogAnimate("error", res.error);
          } else {
            window.location.reload();
          }
        }
      );
    });
  }
  const newAddressForm = document.querySelector("#add-new-address-form");
  if (newAddressForm) {
    formValidate(newAddressForm, schemaAddress, (body) => {
      const apartmentValue = newAddressForm.elements.apartment.value;
      const privateHouse = newAddressForm
        .closest(".modal")
        .querySelector('[name="private-house"]').checked;

      if (!privateHouse) body.apartment = apartmentValue;

      body.street = newAddressForm.elements.street.value;
      body.city = newAddressForm.elements.city.value;

      const house = body.house == "" ? "" : `, д.${body.house}`;
      const apartment = apartmentValue == "" ? "" : `, кв.${apartmentValue}`;

      body.address = `${body.city}, ${body.street}${house}${apartment}`;

      sendData(body, "/api/profile-address/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.reload();
        }
      });
    });
  }
  const newAddressFormMap = document.querySelector("#add-new-address-form-map");
  if (newAddressFormMap) {
    formValidate(newAddressFormMap, schemaAddress, (body) => {
      const apartmentValue = newAddressForm.elements.apartment.value;
      const privateHouse = newAddressForm
        .closest(".modal")
        .querySelector('[name="private-house"]').checked;

      if (!privateHouse) body.apartment = apartmentValue;

      body.street = newAddressForm.elements.street.value;
      body.city = newAddressForm.elements.city.value;

      const house = body.house == "" ? "" : `, д.${body.house}`;
      const apartment = apartmentValue == "" ? "" : `, кв.${apartmentValue}`;

      body.address = `${body.city}, ${body.street}${house}${apartment}`;

      sendData(body, "/api/profile-address/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.reload();
        }
      });
    });
  }
  const wholesalersForm = document.querySelector("#wholesalers-form");
  if (wholesalersForm) {
    formValidate(wholesalersForm, wholesalersSchema, (body) => {
      const volumeInput = wholesalersForm.querySelector(".__counter__input");
      body.message = volumeInput.value;

      sendData(body, "/api/feedback/old-fabric/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          dialogAnimate("help-form");
          clearForm(wholesalersForm);
          volumeInput.value = "0кг";
        }
      });
    });
  }
  const distributionForm = document.querySelector("#distribution-form");
  if (distributionForm) {
    formValidate(distributionForm, distributionSchema, (body) => {
      sendData(body, "/api/mailing/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          clearForm(distributionForm);
        }
      });
    });
  }
  const getMoneyForm = document.querySelector("#get-money-form");
  if (getMoneyForm) {
    formValidate(getMoneyForm, getMoneySchema, (body) => {
      const codeFormPhone = document.querySelector("#code-form-money-phone");
      const select = getMoneyForm.querySelector(".__select_where_from");
      if (select) body.source = select.dataset.attr;

      const cityInput = getMoneyForm.querySelector('[name="city"]');
      const cityTitle = cityInput.closest(".__select__title");
      if (cityTitle.dataset.attr !== "")
        body.city = parseInt(cityTitle.dataset.attr);

      sendData(body, "/api/promocode/get-code/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else if (res.detail) {
          dialogAnimate(
            "error",
            "Вы привысили кол-во попыток, попробуйте позже"
          );
        } else {
          codeFormPhone.value = body.phone;
          codeFormPhone.focus();
          clearForm(getMoneyForm);
          openHeaderMenu("code-money");
          subheadingFixed();
        }
      });
    });
  }
  const confirmMoneyForm = document.querySelector("#code-money-form");
  if (confirmMoneyForm) {
    formValidate(confirmMoneyForm, schemaCode, (body) => {
      sendData(body, "/api/promocode/confirm-code/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.href = "/success/?scenario=coupon";
        }
      });
    });
  }
  const newAddressFormOrder = document.querySelector(
    "#add-new-address-form-order"
  );
  const newAddressFormMapOrder = document.querySelector(
    "#add-new-address-form-map-order"
  );
  if (newAddressFormOrder) {
    formValidate(newAddressFormOrder, schemaAddress, (body) => {
      const apartmentValue = newAddressFormOrder.elements.apartment.value;
      const privateHouse = newAddressFormOrder
        .closest(".modal")
        .querySelector('[name="private-house"]').checked;

      if (!privateHouse) body.apartment = apartmentValue;

      const addressOrderList = document.querySelector(".__address_order__list");
      body.street = newAddressFormOrder.elements.street.value;
      body.city = newAddressFormOrder.elements.city.value;

      const house = body.house == "" ? "" : `, д.${body.house}`;
      const apartment = apartmentValue == "" ? "" : `, кв.${apartmentValue}`;

      body.address = `${body.city}, ${body.street}${house}${apartment}`;

      clearForm(newAddressFormOrder);
      clearForm(newAddressFormMapOrder);
      createAddressLabel(addressOrderList, body.address, body.city);
      closeAllModals();
      dispatchOrderValidate();
      if (userLoggedIn()) sendData(body, "/api/profile-address/");
    });
  }
  if (newAddressFormMapOrder) {
    formValidate(newAddressFormMapOrder, schemaAddress, (body) => {
      const apartmentValue = newAddressFormOrder.elements.apartment.value;
      const privateHouse = newAddressFormOrder.elements.privateHouse.checked;

      if (!privateHouse) body.apartment = apartmentValue;

      const addressOrderList = document.querySelector(".__address_order__list");
      body.street = newAddressFormOrder.elements.street.value;
      body.city = newAddressFormOrder.elements.city.value;

      const house = body.house == "" ? "" : `, д.${body.house}`;
      const apartment = apartmentValue == "" ? "" : `, кв.${apartmentValue}`;

      body.address = `${body.city}, ${body.street}${house}${apartment}`;

      clearForm(newAddressFormOrder);
      clearForm(newAddressFormMapOrder);
      createAddressLabel(addressOrderList, body.address, body.city);
      closeAllModals();
      dispatchOrderValidate();
      if (userLoggedIn()) sendData(body, "/api/profile-address/");
    });
  }
  const orderForm = document.querySelector("#order-form");
  if (orderForm) {
    formValidate(orderForm, orderSchema, (body) => {
      const queries = getCurrentQuery();

      const saveInProfile = document.querySelector("#save-in-profile-checkbox");
      if (userLoggedIn() && saveInProfile.checked)
        body["save_in_profile"] = saveInProfile.checked;

      let idQuery = null;
      if (queries.length > 0) {
        idQuery = getCurrentQuery()[0].order_id;
      }
      if (!!orderForm.dataset.city_name)
        body.city = orderForm.dataset.city_name;
      if (!!orderForm.dataset.address) body.address = orderForm.dataset.address;
      if (!!orderForm.dataset.cdek_order)
        body.cdek_order = { shipment_point: orderForm.dataset.cdek_order };
      if (!!orderForm.dataset.bonus_amount)
        body.bonus_amount = orderForm.dataset.bonus_amount;
      patchData(body, `/api/order/${idQuery}/`)
        .then((res) => {
          const productCards = document.querySelectorAll(".product-card-order");
          if (!userLoggedIn()) {
            const basket = getBasketFromCookie();
            const promises = Array.from(productCards).map((item) => {
              const id = parseInt(item.dataset.id);
              const index = basket.findIndex(
                (item) => item["product_id"] === id
              );
              if (index !== -1) basket.splice(index, 1);
              setCookie("basket", JSON.stringify(basket));
            });
          }
          window.location = res.redirect_url;
        })
        .catch((error) => {
          const data = error.data["error"] || error.data["detail"];
          console.error(data);
          dialogAnimate("error", data);
        });
    });
  }
  const vacancyForm = document.querySelector("#vacancy-form");
  if (vacancyForm) {
    formValidate(vacancyForm, vacancySchema, (body) => {
      const birthdayInput = vacancyForm.querySelector('[name="birthday"]');
      if (birthdayInput.value !== "")
        body.birthday = formatDate(birthdayInput.value);

      const cityInput = vacancyForm.querySelector('[name="city"]');
      if (cityInput.dataset.attr !== "")
        body.city = parseInt(cityInput.dataset.attr);
      sendData(body, "/api/job-feedback/").then((res) => {
        if (res.error) {
          dialogAnimate("error", res.error);
        } else {
          window.location.href = "/success/";
        }
      });
    });
  }
  const downloadCandidatesForm = document.querySelector("#download-candidates");
  if (downloadCandidatesForm) {
    downloadCandidatesForm.onsubmit = (e) => {
      e.preventDefault();
    };
    const createdAfter = downloadCandidatesForm.elements.created_at_after;
    const createdBefore = downloadCandidatesForm.elements.created_at_before;
    const button = downloadCandidatesForm.elements.button;
    button.addEventListener("click", () => {
      getFile(
        `/api/job-feedback/download-candidates/?created_at_after=${createdAfter.value}&created_at_before=${createdBefore.value}`
      ).then((res) => {
        const aElement = document.createElement("a");
        aElement.setAttribute("download", "Список кандидатов");
        const href = URL.createObjectURL(res);
        aElement.href = href;
        aElement.setAttribute("target", "_blank");
        aElement.click();
        URL.revokeObjectURL(href);
      });
    });
  }
  const refundForm = document.querySelector("#refund-form");
  if (refundForm) {
    formValidate(refundForm, refundSchema, (body) => {
      const selectTitle = refundForm.querySelector(".__select__title");
      body.reason = selectTitle.dataset.attr;

      const itemsArray = [];

      const items = refundForm.querySelectorAll(
        ".__select_products__product:checked"
      );
      const promises = Array.from(items).map((item) => {
        const itemObj = {};
        itemObj.product_id = parseInt(item.dataset.id);
        itemObj.quantity = 1;
        itemsArray.push(itemObj);
      });
      Promise.all(promises).then(() => {
        body.items = itemsArray;

        sendData(
          body,
          `/api/order/${refundForm.dataset.id}/make-order-refund/`
        ).then((res) => {
          if (res.error) {
            dialogAnimate("error", res.error);
          } else {
            closeAllModals();
            clearForm(refundForm);
            dialogAnimate("refund");
          }
        });
      });
    });
  }
  const addNewCardForm = document.querySelector("#add-new-card");
  if (addNewCardForm) {
    formValidate(addNewCardForm, addCardSchema, (body) => {
      handleBankCardSubmit(body);
    });
  }
}

function formValidate(form, schema, callBack, formData) {
  form.onsubmit = (e) => {
    e.preventDefault();
  };
  const button = form.elements.button;

  const inputs = Object.keys(getBody(form))
    .map((el) => form.elements[`${el}`])
    .filter(
      (el) =>
        el.type === "text" || el.type === "textarea" || el.type === "password"
    );
  inputs.forEach((el) => {
    el.oninput = (evt) => {
      el.classList.remove("error");

      const body = getBody(evt.target.form);
      validateParse({
        schema: schema,
        body,
      })
        .then((res) => {
          if (button) button.disabled = false;
          if (button && form.dataset.state && form.dataset.state == "disabled")
            button.disabled = true;
          inputs.forEach((el) => {
            el.classList.remove("error");
          });
          if (button)
            button.onclick = () => {
              const formDataBody = new FormData(form);
              if (callBack) formData ? callBack(formDataBody) : callBack(body);
            };
        })
        .catch((error) => {
          const errorString = error.toString();

          const parse = JSON.parse(
            `[${getTextInsideSquareBrackets(errorString)}]`
          );
          const nameErrorInput = parse.map((el) => el.path[0]);
          let input = [];
          for (const errorName of nameErrorInput) {
            input.push(form.elements[`${errorName}`]);
          }
          if (button) button.disabled = true;
          input.forEach((el) => {
            el.classList.add("error");
          });
          if (button) button.onclick = () => {};
        });
    };
  });
}

async function validateParse(validateInfo) {
  try {
    validateInfo.schema.parse(validateInfo.body);
    console.log("Validation successful");
    if (typeof validateInfo?.callback == "function") validateInfo?.callback();
    return true;
  } catch (error) {
    if (error instanceof z.ZodError) {
      // console.error("Validation failed:", error.errors);
      throw new Error(JSON.stringify(error.errors));
    } else {
      // console.error("Unknown error", error);
    }
  }
}

function clearForm(form) {
  const inputs = Object.keys(getBody(form)).map((el) => form.elements[`${el}`]);

  const button = form.elements.button;

  inputs.forEach((elem) => {
    elem.value = "";
  });

  button.disabled = true;
}

function getBody(form) {
  const formData = new FormData(form);
  const body = {};
  for (let [name, value] of formData.entries()) {
    body[name] = value;
  }
  return body;
}

function getQuery(query) {
  const searchParams = new URLSearchParams(window.location.search);
  return searchParams.get(query);
}

export function dialogAnimate(attr, text) {
  const dialog = document.querySelector(
    `.dialog-success[data-dialog="${attr}"]`
  );
  if (!dialog) return;
  dialog.dataset.state = "active";
  setTimeout(() => {
    dialog.dataset.state = "disabled";
  }, 5500);
  if (text) {
    const textBlock = dialog.querySelector(
      ".dialog-success__title-block__text"
    );
    textBlock.innerHTML = text;
  }
}

// Для корректной работы необходимо подключить и активировать эту функцию в app.js

// Пример подключения: import { validation } from "./путь/к/файлу/validation.js";

// Активация: validation();
