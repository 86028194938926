import { debounce, getCurrentQuery, setNewQuery } from "../../utils.js";

export function reviewsFilters() {
    const reviewsFilterElems = document.querySelectorAll('.__filter_elem_reviews');
    if(reviewsFilterElems.length === 0) return;
    const currentOrdering = getCurrentQuery().find(item => item.ordering)?.ordering;
    reviewsFilterElems.forEach(filter=>{
        if(!!currentOrdering) {
            if(currentOrdering.includes(filter.dataset.value)) filter.dataset.state = 'active';
            currentOrdering.includes('-') ? filter.dataset.direction = 'top' : filter.dataset.direction = 'bottom';
        };

        filter.addEventListener('click',()=>{
            filter.dataset.direction = filter.dataset.direction == 'bottom' ? 'top' : 'bottom';
            reviewsFilterElems.forEach(elem=>{
                if(elem == filter) {
                    elem.dataset.state = 'active';
                } else {
                    elem.dataset.state = 'disabled';
                    elem.dataset.direction = 'top';
                }
            });
            if(filter.dataset.state == 'active') {
                let value = filter.dataset.value;
                if(filter.dataset.direction == 'top') value = `-${value}`;
                debouncedSetNewQuery(filter.dataset.query, value);
            };
        });
    });
}

const debouncedSetNewQuery = debounce(setNewQuery, 1000);