export function displaySize() {
    const section = document.querySelector('.__change_size_display__section');
    if(section) {
        const btns = document.querySelectorAll('.__change_size_display__btn');
        btns.forEach(btn=>{
            const value = btn.value;
            btn.addEventListener('click',()=>{
                const cards = section.querySelectorAll('.product-card');
                if(value == 'default') {
                    cards.forEach(card=>{
                        card.classList.remove('product-card_horizontal');
                    });
                } else if (value == 'inline') {
                    cards.forEach(card=>{
                        card.classList.add('product-card_horizontal');
                    });
                };
            });
        });
    };
}