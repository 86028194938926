import { getCurrentQuery } from "../../utils.js";

export function checkForScenario() {
    const textBlock = document.querySelector('.__success_text');
    const activeQueries = getCurrentQuery();
    if(!textBlock || activeQueries.length === 0) return;
    const scenario = activeQueries[0].scenario;
    if(!scenario) return;
    switch (scenario) {
        case 'coupon':
            textBlock.innerHTML = 'Купон был отправлен';
            break;
        case 'review':
            textBlock.innerHTML = 'Твой отзыв был отправлен. Спасибо, что делишься впечталениями';
            break;
    }
}