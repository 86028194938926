import { sendData } from "../../utils.js";
import { calculateOrderPrice } from "./deliveryOrder.js";
import { dispatchOrderValidate } from "./orderValidation.js";

export function cdek() {
    const mapCdek = document.querySelector('#cdek-map');
    if(!mapCdek) return;

    ymaps.ready(init);
    var myMap;

    function init() {
        ymaps.geolocation.get({
            provider: 'yandex',
            autoReverseGeocode: true
        }).then((result)=>{
            const coords = result.geoObjects.position;
            let address = result.geoObjects.get(0).getAdministrativeAreas()[1];
            myMap = new ymaps.Map(mapCdek, {
                center: coords,
                zoom: 13,
                controls: []
            });

            initPlacemarks(address, myMap);

            myMap.events.add('boundschange',(event)=>{
                const newCenter = event.get('newCenter');
                ymaps.geocode(newCenter).then(res=>{
                    const firstGeoObject = res.geoObjects.get(0);
                    const newAddress = firstGeoObject.getAdministrativeAreas()[1];
                    if(!!newAddress && newAddress !== address) {
                        address = newAddress;
                        initPlacemarks(address, myMap);
                    }
                });
            });
        });
    }
}

function initPlacemarks(address, map) {
    const addressBlock = document.querySelector('.__cdek_map__address');

    var myGeoObjects = new ymaps.GeoObjectCollection({}, {
        preset: "islands#redCircleIcon",
        strokeWidth: 4,
        geodesic: true
    });

    sendData({'address': address}, '/api/cdek/get-branches/')
    .then(res=>{

        const branches = res.branches;
        let placemarks = [];

        if(branches.length === 0){
            map.geoObjects.removeAll(myGeoObjects);
            addressBlock.innerText = 'Пункты выдачи СДЕК не найдены';
            return;
        } else {
            addressBlock.innerText = 'Не выбрано';
        }
        for(const info of branches) {
            const location = [info.location.latitude, info.location.longitude];
            let placemark = new ymaps.Placemark(location, {}, {
                iconLayout: "default#image",
                iconImageHref: '/static/assets/images/map-pin-cdek.png',
                iconImageSize: [54, 60],
                iconImageOffset: [-27, -60],
            });

            myGeoObjects.add(placemark);
            placemarks.push(placemark);
            
            placemark.events.add('click', ()=>{
                const orderForm = document.querySelector('#order-form');
                orderForm.dataset.city_name = info.location.city;

                placemarks.forEach(unactive=>{
                    unactive.options.set('iconImageHref', '/static/assets/images/map-pin-cdek.png');
                });
                placemark.options.set('iconImageHref', '/static/assets/images/map-pin-cdek-active.png');
                addressBlock.innerText = info.location.address;
                addressBlock.dataset.city = info.location.city;
                addressBlock.dataset.id = info.code;

                calculateOrderPrice(addressBlock.dataset.city, '136');
                dispatchOrderValidate();
            });
        };

        map.geoObjects.add(myGeoObjects);
    })
    .catch(error=>{
        map.geoObjects.removeAll(myGeoObjects);
        addressBlock.innerText = 'Пункты выдачи СДЕК не найдены';
        addressBlock.dataset.city = '';

        dispatchOrderValidate();
    });
}