import { getBasketFromCookie } from "../../../libraries/BasketLibrary.mjs";
import { userLoggedIn } from "../../utils.js";

export function checkForEmpty() {
    const basketSections = document.querySelectorAll('[data-basket]');
    if(basketSections.length === 0) return;
    const basket = getBasketFromCookie();
    if(basket.length > 0 && !userLoggedIn()) {
        basketSections.forEach(section=>{
            section.dataset.basket = 'full';
        });
    } else if (basket.length == 0 && !userLoggedIn()) {
        basketSections.forEach(section=>{
            section.dataset.basket = 'clear';
        });
    } else if(userLoggedIn()) {
        const basketList = document.querySelector('.__basket_append');
        let dataBasket = 'clear';
        if(basketList.querySelector('.product-card-basket')) dataBasket = 'full';
        basketSections.forEach(section=>{
            section.dataset.basket = `${dataBasket}`;
        });
    };
}