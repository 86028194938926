import { checkForEmpty } from "./checkForEmpty.js";
import { appendCards } from "./appendCards.js";
import { clearWishlist } from "./clearWishlist.js";
import { filtersWishlist } from "./filtersWishlist.js";

function init() {
    checkForEmpty();
    appendCards();
    clearWishlist();
    filtersWishlist();
}

export {
    init   
}