export function select() {
    const selects = document.querySelectorAll('.__select');
    if (selects.length > 0) {
        selects.forEach(select => {
            const selectSingle_title = select.querySelector('.__select__title');
            const selectSingle_labels = select.querySelectorAll('.__select__label');
            const selectSingle_input = select.querySelector('input[type="text"]');

            // Toggle menu
            if (selectSingle_title) {
                selectSingle_title.addEventListener('click', () => {
                    if ('active' === select.getAttribute('data-state')) {
                        select.dataset.state = '';
                        document.removeEventListener('click',setStateUnactive);
                    } else {
                        setStateActive(select);
                    };
                });
            };

            if(selectSingle_input) {
                selectSingle_input.addEventListener('input',()=>{
                    const value = selectSingle_input.value;
                    setStateActive(select);
                    Array.from(selectSingle_labels).map(label=>{
                        label.style.display = label.innerText.toLowerCase().includes(value.toLowerCase()) ? null : 'none';
                    });
                });
            };

            // Close when click to option
            for (let i = 0; i < selectSingle_labels.length; i++) {
                selectSingle_labels[i].addEventListener('click', (evt) => {
                    const selectedText = evt.target.textContent;
                    const dataAttr = evt.target.dataset.attr;
                    if(dataAttr) selectSingle_title.dataset.attr = dataAttr;
                    if(selectSingle_input) {
                        selectSingle_input.value = selectedText;
                    } else {
                        selectSingle_title.textContent = selectedText;
                    }
                    selectSingle_title.setAttribute('value', selectedText);
                    select.setAttribute('data-state', '');
                    Array.from(selectSingle_labels).map(label=>label.style.display = null);
                });
            };

            function setStateUnactive(event) {
                const select = document.querySelector('.__select[data-state="active"]');
                if(!select) return;
                if(!select.contains(event.target)) {
                    select.dataset.state = '';
                };
            }

            function setStateActive(select) {
                select.dataset.state = 'active';
                document.addEventListener('click',setStateUnactive)
            };
        });
    };
}