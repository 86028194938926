import { closeAllMenus } from "./header/headerMenus.js";

export function inset() {
    const inset = document.querySelector('.__inset');
    if(inset) {
        inset.addEventListener('click',()=>{
            closeAllMenus();
        });
    }
}
export function showInset() {
    const inset = document.querySelector('.__inset');
    inset.classList.add('__inset_show');
    document.documentElement.style.overflow = 'hidden';
}
export function hideInset() {
    const inset = document.querySelector('.__inset');
    inset.classList.remove('__inset_show');
    document.documentElement.style.overflow = null;
}