export function currentCityCheck() {
    const contactCards = document.querySelectorAll('.contact-card');
    if(contactCards.length === 0) return;
    const currentLocationCity = document.querySelector('.__current_location__city');
    contactCards.forEach(card=>{
        const text = card.querySelector('.contact-card__text');
        const spans = text.querySelectorAll('span');
        spans.forEach(span=>{
            if(span.innerText == currentLocationCity.innerText) {
                span.dataset.state = 'active';
            };
        });
    });
}