import {hideInset, showInset} from "../inset.js";
import {debounce, getData} from "../utils.js";
import {closeAllMenus} from "./headerMenus.js";

export function headerSearch() {
    const searchInputs = document.querySelectorAll('.__header_search__input');
    const headerSearchBlock = document.querySelector('.__header_search__block');
    if (!searchInputs.length === 0 || !headerSearchBlock) return;

    let notFoundBlock = document.querySelector('.__header_search__block__not-found');
    let itemsBlock = headerSearchBlock.querySelector('.__header_search__block__items');
    let categoriesBlock = headerSearchBlock.querySelector('.__header_search__block__categories');
    const searchCancelBtn = document.querySelector('.__header_search__block__cancel_btn');

    if (window.innerWidth < 769) {
        notFoundBlock = document.querySelector('.__header_search__block__not-found_mobile');
        itemsBlock = document.querySelector('.__header_search__block__items_mobile');
        categoriesBlock = document.querySelector('.__header_search__block__categories_mobile');
    }
    ;

    searchInputs.forEach(searchInput => {
        searchInput.addEventListener('input', () => {
            removeEventListener("keydown", () => {
            });
            if (!!searchInput.value) {
                debouncedSearcherFunction(searchInput.value);
            } else {
                window.innerWidth > 768 && closeAllMenus();
                headerSearchBlock.classList.remove('active');
            }
            ;
        });
    });

    searchCancelBtn.addEventListener('click', () => {
        clearSearchers();
    });

    const debouncedSearcherFunction = debounce(searcherFunction, 500);

    function searcherFunction(value) {
        window.innerWidth > 768 && closeAllMenus(true);


        getData(`/api/search/?q=${value}`)
            .then(res => {
                headerSearchBlock.classList.add('active');
                appendItems(res.products, value, itemsBlock);
                appendCategories(res.categories, categoriesBlock);
                if (res.categories.length === 0 && res.products.length === 0) {
                    notFound(notFoundBlock, categoriesBlock, itemsBlock);
                } else {
                    somethingFound(notFoundBlock, categoriesBlock, itemsBlock);
                }
                ;
                showInset();

                Array.from(document.querySelectorAll('.__applySearch')).forEach((item) => {
                    item.addEventListener('click', () => {
                        window.location.href = "/catalog/search/?q=" + value;
                    })
                })

                addEventListener("keydown", (event) => {
                    if (event.key == "Enter") {
                        window.location.href = "/catalog/search/?q=" + value;
                    }
                });

            })
    };
}

export function closeSearcher() {
    const headerSearchBlock = document.querySelector('.__header_search__block');
    headerSearchBlock.classList.remove('active');
}

function clearSearchers() {
    const searchInputs = document.querySelectorAll('.__header_search__input');
    if (searchInputs.length === 0) return;

    searchInputs.forEach(searchInput => {
        searchInput.value = '';
        const inputEvent = new CustomEvent('input', {bubbles: true});
        searchInput.dispatchEvent(inputEvent);
    });
}

function appendItems(data, value, block) {
    block.innerHTML = '';
    const fragment = document.createDocumentFragment();
    const promises = data.map(info => {
        createItem(info, value, fragment)
    });
    Promise.all(promises).then(res => {
        block.appendChild(fragment);
    });
}

function appendCategories(data, block) {
    block.innerHTML = '';
    const fragment = document.createDocumentFragment();
    const promises = data.map(info => {
        createCategory(info, fragment)
    });
    Promise.all(promises).then(res => {
        block.appendChild(fragment);
    });
}

function somethingFound(notFoundBlock, categoriesBlock, itemsBlock) {
    notFoundBlock.dataset.state = 'disabled';
    categoriesBlock.dataset.state = 'active';
    itemsBlock.dataset.state = 'active';
}

function notFound(notFoundBlock, categoriesBlock, itemsBlock) {
    notFoundBlock.dataset.state = 'active';
    categoriesBlock.dataset.state = 'disabled';
    itemsBlock.dataset.state = 'disabled';
}

function createItem(info, value, fragment) {
    const item = document.createElement('a');
    item.href = `/catalog/p/${info.slug}/`;
    item.classList.add('search-bullet');

    let text = info.name;
    text = text.replace(value, `<span>${value}</span>`);

    item.innerHTML = `
        <div class="search-bullet__text">${text}</div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86477 6.26394C10.0335 6.0954 10.2623 6.00073 10.5008 6.00073C10.7393 6.00073 10.968 6.0954 11.1368 6.26394L16.2368 11.3639C16.4053 11.5327 16.5 11.7614 16.5 11.9999C16.5 12.2384 16.4053 12.4672 16.2368 12.6359L11.1368 17.7359C10.9662 17.8949 10.7405 17.9815 10.5073 17.9774C10.2742 17.9732 10.0517 17.8788 9.88682 17.7139C9.72193 17.549 9.62747 17.3265 9.62336 17.0934C9.61925 16.8602 9.70579 16.6346 9.86477 16.4639L14.3288 11.9999L9.86477 7.53594C9.69623 7.36719 9.60156 7.13844 9.60156 6.89994C9.60156 6.66144 9.69623 6.43269 9.86477 6.26394Z" fill="#383838" fill-opacity="0.6"/>
        </svg>
    `;
    fragment.appendChild(item);
}

function createCategory(info, fragment) {
    const category = document.createElement('a');
    category.href = `/catalog/${info.slug}/`;
    category.classList.add('search-bullet', 'search-bullet_category');

    const photo = info.photo ? info.photo : '/static/assets/images/stub.webp';

    let parent = '';
    if (!!info.parent) {
        parent = `
            <span>-</span>
            <a href="/catalog/${info.parent.slug}/" class="link link_size_small link_subdued">
                ${info.parent.name}
            </a>
        `;
    }
    ;

    category.innerHTML = `
        <div class="search-bullet_category__info-block">
            <div class="search-bullet_category__image">
                <img src="${photo}" alt="category image">
            </div>
            <div class="search-bullet_category__text-block">
                <div class="search-bullet_category__title">${info.name}</div>
                <div class="breadcrumbs">
                    <a href="/" class="link link_size_small link_subdued">
                        Индекс
                    </a>
                    ${parent}
                </div>
            </div>
        </div>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M9.86477 6.26394C10.0335 6.0954 10.2623 6.00073 10.5008 6.00073C10.7393 6.00073 10.968 6.0954 11.1368 6.26394L16.2368 11.3639C16.4053 11.5327 16.5 11.7614 16.5 11.9999C16.5 12.2384 16.4053 12.4672 16.2368 12.6359L11.1368 17.7359C10.9662 17.8949 10.7405 17.9815 10.5073 17.9774C10.2742 17.9732 10.0517 17.8788 9.88682 17.7139C9.72193 17.549 9.62747 17.3265 9.62336 17.0934C9.61925 16.8602 9.70579 16.6346 9.86477 16.4639L14.3288 11.9999L9.86477 7.53594C9.69623 7.36719 9.60156 7.13844 9.60156 6.89994C9.60156 6.66144 9.69623 6.43269 9.86477 6.26394Z" fill="#383838" fill-opacity="0.6"/>
        </svg>
    `;
    fragment.appendChild(category);
}