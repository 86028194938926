import { checkForEmpty } from "./checkForEmpty.js";
import { appendCards } from "./appendCards.js";
import { checkboxes } from "./checkboxes.js";
import { clearBasket } from "./clearBasket.js";

function init() {
    checkForEmpty()
    appendCards()
    checkboxes()
    clearBasket()
}

export {
    init   
}