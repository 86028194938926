import BasketLibrary from "../../libraries/BasketLibrary.mjs";
import { basketBadge } from "../utils.js";

export function basketButtons() {
    const basketBtns = document.querySelectorAll('.__add_in_basket');
    basketBtns.forEach(addBtn=>{
        const productId = addBtn.dataset.id;
        const addBtnClass = new BasketLibrary(addBtn,{
            product_id: productId,
            badge: basketBadge,
            quantity: 1,
            activeInnerHTML: 'В корзине',
            oldInnerHTML: 'Купить'
        });
    });
}