// async function getConfig() {
//     try {
//         const response = await fetch('../package.json');
//         if (!response.ok) {
//             throw new Error('Error fetching package.json: ' + response.statusText);
//         }
//         const packageJson = await response.json();
//         return packageJson.wishlistConfig;
//     } catch (error) {
//         console.error('Error loading package.json:', error);
//     }
// }

let productKey = 'product_id';
// const getProductKey = await getConfig().then(config => {productKey = config.productKey});

class ProductClass{
    constructor(options) {
        this[productKey] = options[productKey]
    }
}

class AddInWishlist extends ProductClass{
    constructor(options) {
        super(options)
    }
    addFunctionality() {
        const id = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        if(userLoggedIn()) {
            const productObj = {};
            productObj[productKey] = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
            sendData(productObj, '/api/wishlist/add/');
        } else {
            const wishlist = getWishlistFromCookie();
            let index;
            index = wishlist.findIndex(item => item[productKey] === id);

            if(index !== -1) wishlist.splice(index, 1);

            wishlist.push({
                [productKey]: id
            });

            setCookie('wishlist', JSON.stringify(wishlist));
        }
    }
}

class RemoveFromWishlist extends AddInWishlist{
    constructor(options) {
        super(options)
    }
    removeFunctionality() {
        const id = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        if(userLoggedIn()) {
            const productObj = {};
            productObj[productKey] = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
            deleteData(productObj, '/api/wishlist/remove/');
        } else {
            const wishlist = getWishlistFromCookie();

            let index;
            index = wishlist.findIndex(item => item[productKey] === id);

            if(index !== -1) wishlist.splice(index, 1);
            setCookie('wishlist', JSON.stringify(wishlist));
        }
    }
}

class WishlistLibrary extends RemoveFromWishlist{
    constructor(Elem, options) {
        super(options)

        this.$Elem = (typeof Elem === 'string') ? document.querySelector(`${Elem}`) : Elem
        this.$Badge = (typeof options.badge === 'string') ? document.querySelectorAll(`${options.badge}`) : options.badge
        this.activeInnerHTML = options.activeInnerHTML

        this.wishlistFunctionality()
        this.checkForWishlist()
    }
    checkForWishlist() {
        if(userLoggedIn()) return;
        const wishlist = getWishlistFromCookie();
        if(wishlist.length === 0) return;

        const id = isNaN(this[productKey]) ? this[productKey] : parseInt(this[productKey]);
        for(const item of wishlist) {
            if(item[productKey] === id) {
                this.$Elem.dataset.state = 'active';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.activeInnerHTML;
            };
        };
    }
    wishlistFunctionality() {
        const oldInnerHTML = this.$Elem.innerHTML;

        this.$Elem.addEventListener('click',()=>{
            if(!this.$Elem.dataset.state) this.$Elem.dataset.state = 'unactive';

            if(this.$Elem.dataset.state === 'active') {
                this.$Elem.dataset.state = 'unactive';
                if(this.activeInnerHTML) this.$Elem.innerHTML = oldInnerHTML;
                super.removeFunctionality()
            } else if (this.$Elem.dataset.state === 'unactive') {
                this.$Elem.dataset.state = 'active';
                if(this.activeInnerHTML) this.$Elem.innerHTML = this.activeInnerHTML;
                super.addFunctionality()
            }
            
            if(!this.$Badge || this.$Badge.length === 0) return;
            this.updateBadge()
        });

        if(!this.$Badge || this.$Badge.length === 0) return;
        this.updateBadge()
    }
    updateBadge() {
        if(userLoggedIn()) {
            getData('/api/wishlist/get-info/')
            .then(res=>{
                for(const badge of this.$Badge) {
                    badge.dataset.value = res.product_count;
                };
            });
        } else {
            const wishlist = getWishlistFromCookie();
            for(const badge of this.$Badge) {
                badge.dataset.value = wishlist.length;
            };
        };
    };
}


async function getData(url) {
    const response = await fetch(url, {
        method: "GET",
    });
    
    if(response.ok) return response.json();
    else throw new Error(response.statusText);
};

async function deleteData(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "DELETE",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Bearer ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

async function sendData(data, url) {
    const csrfToken = document.querySelector('[name=csrfmiddlewaretoken]').value;
    if(userLoggedIn()) {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                'Content-Type': "application/json",
                'X-CSRFToken': csrfToken,
                'Authorization': 'Bearer ' + userLoggedIn(),
            },
            body: JSON.stringify(data),
        });
        if(response.ok) return response.json();
        else throw new Error(response.statusText);
    }
};

const getCookiesWishlist = () => {
    return decodeURIComponent(document.cookie.replace(/(?:(?:^|.*;\s*)wishlist\s*\=\s*([^;]*).*$)|^.*$/, "$1"));
}
export function getWishlistFromCookie() {
    const wishlistCookies = getCookiesWishlist();
    return wishlistCookies ? JSON.parse(wishlistCookies) : [];
}
const userLoggedIn = () => {
    return document.cookie.replace(/(?:(?:^|.*;\s*)access_token\s*\=\s*([^;]*).*$)|^.*$/, "$1");
}
function setCookie(name, value, options = {}) {
    options = {
        path: '/',
      ...options
    };
  
    if (options.expires instanceof Date) {
      options.expires = options.expires.toUTCString();
    }
  
    let updatedCookie = name + "=" + value;
  
    for (let optionKey in options) {
      updatedCookie += "; " + optionKey;
      let optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += "=" + optionValue;
      }
    }
  
    document.cookie = updatedCookie;
};


export default WishlistLibrary;