import { getWishlistFromCookie } from "../../../libraries/WishlistLibrary.mjs";
import { productCardAnimations } from "../../animations/productCardAnimations.js";
import { createCard } from "../../createCard.js";
import { getCurrentQuery, getData, sendData, userLoggedIn } from "../../utils.js";

export function appendCards() {
    const wishlistList = document.querySelector('.__wishlist_append');
    if(!wishlistList || userLoggedIn()) return;
    const wishlistCookies = getWishlistFromCookie();

    const queries = getCurrentQuery();
    let categoryQuery = null;
    if(queries.length > 0) {
        categoryQuery = getCurrentQuery()[0].category;
    }

    const fragment = document.createDocumentFragment();
    if(categoryQuery) {
        sendData(wishlistCookies, `/api/wishlist/filter-products/?category=${categoryQuery}`)
        .then(res=>{
            const promises = res.map((key) => {
                return getData(`/api/products/${key.id}/`)
                    .then(async (res) => {
                        const card = await createCard(res, 'product-card_wishlist');
                        fragment.appendChild(card);
                    })
                    .catch(err => console.error('Error creating card:', err));
            });
            Promise.all(promises).then(() => {
                wishlistList.appendChild(fragment);
                productCardAnimations(wishlistList);
            });
        });
    } else {
        const promises = wishlistCookies.map((key) => {
            return getData(`/api/products/${key.product_id}/`)
                .then(async (res) => {
                    const card = await createCard(res, 'product-card_wishlist');
                    fragment.appendChild(card);
                })
                .catch(err => console.error('Error creating card:', err));
        });
        Promise.all(promises).then(() => {
            wishlistList.appendChild(fragment);
            productCardAnimations(wishlistList);
        });
    }
}