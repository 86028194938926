export function locationClarification() {
    const popup = document.querySelector('.location-clarification-popup');
    if(!popup) return;
    const currentCitySpan = popup.querySelector('.__current_city__span');
    const btnClose = popup.querySelector('.__current_city__btn_close');
    if(!ymaps.geolocation) return;
    ymaps.geolocation.get({
        provider: 'yandex',
        autoReverseGeocode: true
    }).then((result)=>{
        currentCitySpan.innerHTML = `${result.geoObjects.get(0).getLocalities()[0]}`;
        // popup.dataset.state="active";
        btnClose.addEventListener('click',()=>{
            popup.dataset.state="disabled";
            setTimeout(() => {
                popup.parentNode.removeChild(popup);
            }, 350);
        });
    });
}