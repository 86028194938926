import { accountDeleted } from './accountDeleted.js';
import { needLogin } from './needLogin.js';

function init() {
    needLogin();
    accountDeleted();
}

export {
    init   
}