export function dialogCookie() {
    document.addEventListener('DOMContentLoaded', function () {
        const dialogCookieMenu = document.querySelector(".dialog-cookie");
        if(document.cookie.includes("warning=true") || !dialogCookieMenu) return;
        dialogCookieMenu.dataset.state = 'active';
    
        const dialogCookieBtnAccept = document.querySelector(".__cookie_dialog__btn_accept");
        if(!dialogCookieBtnAccept) return;
        dialogCookieBtnAccept.addEventListener("click", () => {
            dialogCookieMenu.dataset.state = 'unactive';
            document.cookie = "warning=true; max-age=2592000; path=/";
        });

        const dialogCookieBtnDeny = document.querySelectorAll(".__cookie_dialog__btn_deny");
        if(dialogCookieBtnDeny.length === 0) return;
        dialogCookieBtnDeny.forEach((btn) => {
            btn.addEventListener("click", () => {
                dialogCookieMenu.dataset.state = 'unactive';
            });
        });
    });
}