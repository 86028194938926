export function aboutPageAnimations() {
    const aboutVideo = document.querySelector('.about-video-section__video');
    if(aboutVideo) {
        let animationPlayed = false;
        document.addEventListener('scroll',()=>{
            if(checkForView(aboutVideo) && !animationPlayed) {
                aboutVideo.classList.remove('unactive');
                animationPlayed = true;
            }
        }, { passive: true });
    }
    const benefitsSection = document.querySelector('.about-benefits-section');
    if(benefitsSection) {
        const dataImages = benefitsSection.querySelectorAll('[data-number]');
        ScrollTrigger.create({
            trigger: benefitsSection,
            start: 'center center',
            end: '+=1000',
            scrub: true,
            pin: true,
            onUpdate: (self) => {
                let number;
                if(self.progress < 1 / 3) {
                    number = 1;
                } else if(self.progress > 1 / 3 && self.progress < 1 / 3 * 2) {
                    number = 2;
                } else if(self.progress > 1 / 3 * 2 && self.progress < 1 / 3 * 3) {
                    number = 3;
                }
                dataImages.forEach(image=>{
                    if(parseInt(image.dataset.number) == number) {
                        image.dataset.state = 'active';
                    } else {
                        image.dataset.state = 'unactive';
                    }
                })
            }
        });
    }
}

function checkForView(section) {
    const sectionRect = section.getBoundingClientRect();
    if (sectionRect.top * 2 < window.innerHeight && sectionRect.bottom > window.innerHeight / 2) {
        return true;
    } else {
        return false;
    }
}