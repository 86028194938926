export function shopsMap() {
    const shopsMap = document.querySelector('#shops-map');
    if(!shopsMap) return;
    const coords = shopsMap.dataset.coords;
    let coordsDefault = [55.755864, 37.617698];
    let allCoords = [];
    if(coords) coordsDefault = coords.split(';');
    coordsDefault.forEach((splitted, index)=>{
        if(splitted == '') return;
        let firstPart = parseFloat(splitted.split(',')[0].trim());
        let secondPart = parseFloat(splitted.split(',')[1].trim());
        if (!isNaN(firstPart) && !isNaN(secondPart)) {
            const fullCoordsItem = [firstPart, secondPart];
            allCoords.push(fullCoordsItem);
            if(index === 0) coordsDefault = fullCoordsItem;
        };
    });

    ymaps.ready(init);
    var myMap;

    function init() {
        if(window.innerWidth > 768) {
            myMap = new ymaps.Map(shopsMap, {
                center: coordsDefault, 
                zoom: 13,
                controls: []
            });
        } else {
            myMap = new ymaps.Map(shopsMap, {
                center: coordsDefault, 
                zoom: 16,
                controls: []
            });
        };

        let myCollection = new ymaps.GeoObjectCollection();
        const promises = allCoords.map(elem=>{
            if(window.innerWidth > 768) {
                let placemark = new ymaps.Placemark(elem, {
                    coordinates: elem,
                }, {
                    iconLayout: "default#image",
                    iconImageHref: '/static/assets/images/map-pin-active.png',
                    iconImageSize: [54, 60],
                    iconImageOffset: [-27, -60]
                });

                myCollection.add(placemark) 
            } else {
                let placemark = new ymaps.Placemark(elem, {
                    coordinates: elem,
                }, {
                    iconLayout: "default#image",
                    iconImageHref: '/static/assets/images/map-pin-active.png',
                    iconImageSize: [42, 46],
                    iconImageOffset: [-21, -46]
                });

                myCollection.add(placemark)
            };
        });

        const shopCards = document.querySelectorAll('.__shop_card');

        myCollection.events.add('click',(e)=>{
            const target = e.get('target');

            let otherIsActive = false;
            myCollection.each(marker=>{
                if(marker !== target && marker.options.get('iconImageHref') == '/static/assets/images/map-pin-active.png') {
                    otherIsActive = true;
                }
            });

            if(target.options.get('iconImageHref') == '/static/assets/images/map-pin-active.png' && otherIsActive) {
                setTargetActiveOnly();
            } else if (target.options.get('iconImageHref') == '/static/assets/images/map-pin-active.png' && !otherIsActive) {
                myCollection.each(marker=>marker.options.set('iconImageHref', '/static/assets/images/map-pin-active.png'));
                shopCards.forEach(card=>card.dataset.state = null);
            } else if (target.options.get('iconImageHref') == '/static/assets/images/map-pin.png') {
                setTargetActiveOnly();
            };
            


            function setTargetActiveOnly() {
                myCollection.each(marker=>{
                    if(marker !== target) {
                        marker.options.set('iconImageHref', '/static/assets/images/map-pin.png');
                    } else {
                        marker.options.set('iconImageHref', '/static/assets/images/map-pin-active.png');
                    };
                });
                shopCards.forEach(card=>{
                    if(card.dataset.coords == target.properties.get('coordinates')) {
                        card.dataset.state = 'active';
                    } else {
                        card.dataset.state = 'unactive';
                    };
                });
            };

        });

        shopCards.forEach(card=>{
            const cardCoords = card.dataset.coords;
            card.addEventListener('click',()=>{
                let otherIsActive = false;

                shopCards.forEach(unactive=>{
                    if(unactive !== card && unactive.dataset.state !== 'unactive') otherIsActive = true;
                });

                let targetMarker;
                myCollection.each(marker=>{
                    if(marker.properties.get('coordinates') == cardCoords) targetMarker = marker;
                });
                if(!targetMarker) return;

                if(card.dataset.state == 'active' && otherIsActive) {
                    setTargetActiveOnly();
                } else if (card.dataset.state == 'active' && !otherIsActive) {
                    myCollection.each(marker=>marker.options.set('iconImageHref', '/static/assets/images/map-pin-active.png'));
                    shopCards.forEach(card=>card.dataset.state = null);
                } else if(card.dataset.state !== 'active') {
                    setTargetActiveOnly();
                };


                function setTargetActiveOnly() {
                    shopCards.forEach(unactive=>{
                        if(unactive !== card) {
                            unactive.dataset.state = 'unactive';
                        }
                    });
                    card.dataset.state = 'active';
                    myCollection.each(marker=>{
                        if(marker == targetMarker) {
                            marker.options.set('iconImageHref', '/static/assets/images/map-pin-active.png');
                        } else {
                            marker.options.set('iconImageHref', '/static/assets/images/map-pin.png');
                        };
                    });
                };
            });
        });

        Promise.all(promises).then(()=>{
            myMap.geoObjects.add(myCollection);
        });
    };
}