import '../styles/main.scss';

import { accordion } from "./modules/base/accordion.js";
import { inset } from "./modules/inset.js";

import { header } from "./modules/header/header.js";
import { headerMenus } from "./modules/header/headerMenus.js";
import { headerSearch } from "./modules/header/headerSearch.js";
import { searchCities } from "./modules/header/searchCities.js";

import * as pages from './modules/pages/index.js';

import { slider } from "./modules/slider.js";
import { inputs } from "./modules/base/inputs.js";
import { counter } from "./modules/counter.js";
import { anchor } from "./modules/base/anchor.js";
import { modals } from "./modules/base/modals.js";
import { select } from "./modules/base/select.js";

import { calendar } from "./modules/calendar.js";
import { validation } from "./modules/validation.js";
import { filters } from "./modules/filters.js";
import { pagination } from "./modules/pagination.js";
import { endlessPagination } from "./modules/endlessPagination.js";
import { shopsMap } from "./modules/shopsMap.js";
import { address } from "./modules/address.js";
import { dialogCookie } from "./modules/dialogCookie.js";
import { locationClarification } from "./modules/locationClarification.js";
import { downloadMobileApp } from "./modules/downloadMobileApp.js";
import { refund } from "./modules/refund.js";
import { checkRefreshDate } from "./modules/checkRefreshDate.js";

import { basketButtons } from "./modules/basket/basketButtons.js";
import { wishlistButtons } from "./modules/wishlist/wishlistButtons.js";

import { productCardAnimations } from "./modules/animations/productCardAnimations.js";
import { parallax } from "./modules/animations/parallax.js";
import { aboutPageAnimations } from "./modules/animations/aboutPageAnimations.js";
import { getFullUserInfo } from "./modules/getFullUserInfo.js";

accordion()
inset()

header()
headerMenus()
headerSearch()
searchCities()

slider()
inputs()
counter()
anchor()
modals()
select()

calendar()
validation()
filters()
pagination()
endlessPagination()
shopsMap()
address()
dialogCookie()
locationClarification()
downloadMobileApp()
refund()
checkRefreshDate()

basketButtons()
wishlistButtons()

productCardAnimations()
parallax()
aboutPageAnimations()

getFullUserInfo();